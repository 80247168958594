import React, { Component } from 'react';
import '../css/tooltip.css'

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  showTooltip = () => {
    this.setState({ isVisible: true });
  };

  hideTooltip = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const { children, content } = this.props;
    const { isVisible } = {isVisible: true} //this.state;

    return (
      <div
        className="tooltip-wrapper"
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
        style={{ display: 'inline-block', position: 'relative' }}
      >
        {children}
        {isVisible && (
          <div className={`tooltip-content ${this.props.location || 'top-middle'}`}>
            {content}
          </div>
        )}
      </div>
    );
  }
}

export default Tooltip;
