import React from 'react'
import { useEffect, useState, useContext, useMemo, useCallback } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { HealthAndSafety, Done, AssignmentLate } from '@mui/icons-material'
import { ROLES } from '../auth/role'
import axios from 'axios'
import { ApiGateway } from '../config/config'
import UserContext from '../auth/UserContext'
import RoleContext from '../auth/RoleContext'
import Page from '../layout/Page'
import FormViewCommandBar from '../commandBar/FormViewCommandBar'
import { FormHelperText, FormControlLabel, Checkbox, Autocomplete, Grid, InputLabel, MenuItem, Select, TextField, Typography, } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
import { getRequestConfig, getToken } from '../auth/auth'
import { debounce } from '@mui/material/utils';
import FormElement from './FormElement'
import FormCard from './FormCard'
import ConfirmDialog from '../form/ConfirmDialog'
import Button from '@mui/material/Button';
import Validate from '../validator/Validate'
import PhoneInput from '../form/PhoneInput'
import ProductSelection from './ProductSelection'
import { Add } from '@mui/icons-material'
import OwnershipSelection from './OwnershipSelection'
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';

import {
  eventStatuses,
  rcaCapChoices,
  coqStatuses,
  lessonsLearnedChoices,
  capValidationChoices,
} from './common.js'

export default function IMLogFormPage(props = {}) {
  const roleDetails = useContext(RoleContext)
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const [validationMessage, setValidationMessage] = useState('')
  const [imLogId] = useState(params.id || undefined)
  const [dirty, setDirty] = useState({})
  const [isValid, setIsValid] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [eventTypeList, setEventTypeList] = useState([])
  const [issueTypeList, setIssueTypeList] = useState([])
  const [managedByList, setManagedByList] = useState([])
  const [managedBySearch, setManagedBySearch] = useState("")
  const [managedBySelected, setManagedBySelected] = useState('')
  const [governanceManagerAssignedList, setGovernanceManagerAssignedList] = useState([])
  const [governanceManagerAssignedSearch, setGovernanceManagerAssignedSearch] = useState("")
  const [governanceManagerAssignedSelected, setGovernanceManagerAssignedSelected] = useState('')
  const [productionCodes, setProductionCodes] = useState({})
  const [products, setProducts] = useState([{}])
  const [ownerships, setOwnerships] = useState([{}])
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)


  const [validatedFields, setValidatedFields] = useState([])
  const [errors, setErrors] = useState({})

  const defaultValue = ''
  const [newRecord, setNewRecord] = useState(location?.state || {
    eventTypeId: defaultValue,
    title: defaultValue,
    managedBy: defaultValue,
    eventDate: defaultValue,
    description: defaultValue,
    issueTypeId: defaultValue,
    corpIMCRNumber: defaultValue,
    eventStatus: defaultValue,
    governanceManagerAssigned: defaultValue,
    facilityCodeId: defaultValue,
    rcaChoiceId: defaultValue,
    capChoiceId: defaultValue,
    costOfQualityStatus: defaultValue,
    costOfQuality: defaultValue,
    lessonsLearnedChoiceId: defaultValue,
    capValidationChoiceId: defaultValue,
    imcrIatClosedDate: defaultValue,

    rcaDueDate: defaultValue,
    capRecordsReceivedDate: defaultValue,
    capOverdue: defaultValue,
    notifiedBy: defaultValue,
    dateNotified: defaultValue,
    submitterName: defaultValue,
    submitterEmail: defaultValue,
    submitterPhoneNumber: defaultValue,
    ownershipIMProvidedTheFollowingInstructions: defaultValue,
    otherComments: defaultValue,
    createdBy: defaultValue,
  })

  const validateNewForm = useCallback((_newRecord = newRecord) => {
    const _validated = [
      // section 1
      {
        name: 'title',
        section: 1,
        valid: Validate.required('title', newRecord.title).isValid,
        value: newRecord.title,
        required: true,
      },
      {
        name: 'description',
        section: 1,
        valid: Validate.required('description', newRecord.description).isValid,
        value: newRecord.description,
        required: true,
      },
      {
        name: 'eventDate',
        section: 1,
        valid: Validate.date('eventDate', newRecord.eventDate).isValid,
        value: newRecord.eventDate,
        required: true,
      },
      {
        name: 'eventTypeId',
        section: 1,
        valid: Validate.numberRequired('eventTypeId', newRecord.eventTypeId + '').isValid,
        value: newRecord.eventTypeId
      },
      {
        name: 'eventStatus',
        section: 1,
        valid: Validate.required('eventStatus', newRecord.eventStatus).isValid,
        value: newRecord.eventStatus
      },
      {
        name: 'issueTypeId',
        section: 1,
        valid: Validate.numberRequired('issueTypeId', newRecord.issueTypeId).isValid,
        value: newRecord.issueTypeId,
        required: true,
      },
      // section 2
      {
        name: 'dateNotified',
        section: 2,
        valid: Validate.date('dateNotified', newRecord.dateNotified).isValid,
        value: newRecord.dateNotified
      },
      {
        name: 'notifiedBy',
        section: 2,
        valid: Validate.required('notifiedBy', newRecord.notifiedBy).isValid,
        value: newRecord.notifiedBy
      },
      {
        name: 'submitterName',
        section: 2,
        valid: Validate.required('submitterName', newRecord.submitterName).isValid,
        value: newRecord.submitterName
      },
      {
        name: 'submitterEmail',
        section: 2,
        valid: Validate.email('submitterEmail', newRecord.submitterEmail).isValid,
        value: newRecord.submitterEmail
      },
      {
        name: 'submitterPhoneNumber',
        section: 2,
        valid: Validate.numberRequired('submitterPhoneNumber', newRecord.submitterPhoneNumber, { length: 10 }).isValid,
        value: newRecord.submitterPhoneNumber
      },
      // section 3
      ...(ownerships.reduce((s, x) => {
        if (x?.selectionType === 'other' || x?.otherOwnership) {
          s.push(
            ...[
              {
                name: 'otherOwnership',
                section: 3,
                valid: Validate.required('otherOwnership', x?.otherOwnership).isValid,
                value: x?.otherOwnership
              },
            ]
          )
        } else {
          if (`${x?.ownershipId}` === '-100') {
            s.push(
              ...[
                {
                  name: 'supplierName',
                  section: 3,
                  valid: Validate.required('supplierName', x?.supplierName).isValid,
                  value: x?.supplierName
                },
              ]
            )
          }
          if (`${x?.ownershipId}` === '-101') {
            s.push(
              ...[
                {
                  name: 'distributionCenterId',
                  section: 3,
                  valid: Validate.numberRequired('distributionCenterId', x?.distributionCenterId).isValid,
                  value: x?.distributionCenterId
                },
              ]
            )
          }
          if (!['-100', '-101'].includes(`${x?.ownershipId}`)) {
            s.push(
              ...[
                {
                  name: 'ownershipId',
                  section: 3,
                  valid: Validate.numberRequired('ownershipId', x?.ownershipId).isValid,
                  value: x?.ownershipId
                },
                {
                  name: 'manufacturerId',
                  section: 3,
                  valid: Validate.numberRequired('manufacturerId', x?.manufacturerId).isValid,
                  value: x?.manufacturerId
                },
              ]
            )
          }
        }
        return s
      }, [])),
      // section 4
      ...(products.reduce((s, x) => {
        if (x?.selectionType === 'other' || x?.otherProduct) {
          s.push(
            ...[{
              name: 'otherProduct',
              section: 4,
              valid: Validate.required('otherProduct', x.otherProduct).isValid,
              value: x.otherProduct
            },])
        } else {
          s.push(
            ...[{
              name: 'brand',
              section: 4,
              valid: Validate.required('brand', x.brand).isValid,
              value: x.brand
            },
            {
              name: 'product',
              section: 4,
              valid: Validate.required('product', x.product).isValid,
              value: x.product
            },
            {
              name: 'packageMaterial',
              section: 4,
              valid: Validate.required('packageMaterial', x.packageMaterial).isValid,
              value: x.packageMaterial
            },
            {
              name: 'packageUnitType',
              section: 4,
              valid: Validate.required('packageUnitType', x.packageUnitType).isValid,
              value: x.packageUnitType
            },
            {
              name: 'packageType',
              section: 4,
              valid: Validate.required('packageType', x.packageType).isValid,
              value: x.packageType
            },
            {
              name: 'packagePackMaterial',
              section: 4,
              valid: Validate.required('packagePackMaterial', x.packagePackMaterial).isValid,
              value: x.packagePackMaterial
            },
            {
              name: 'servingSizeUom',
              section: 4,
              valid: Validate.required('servingSizeUom', x.servingSizeUom).isValid,
              value: x.servingSizeUom
            },
            {
              name: 'servingSizeValue',
              section: 4,
              valid: Validate.numberRequired('servingSizeValue', x.servingSizeValue).isValid,
              value: x.servingSizeValue
            },
            {
              name: 'productDateCode',
              section: 4,
              valid: Validate.required('productDateCode', x.productDateCode).isValid,
              value: x.productDateCode
            },])
        }
        return s
      }, [])),
      // section 5
      {
        name: 'managedBy',
        section: 5,
        valid: Validate.required('managedBy', newRecord.managedBy).isValid,
        value: newRecord.managedBy
      },
      {
        name: 'governanceManagerAssigned',
        section: 5,
        valid: Validate.required('governanceManagerAssigned', newRecord.governanceManagerAssigned).isValid,
        value: newRecord.governanceManagerAssigned
      },
      {
        name: 'corpIMCRNumber',
        section: 5,
        valid: Validate.numberRequired('corpIMCRNumber', newRecord.corpIMCRNumber).isValid,
        value: newRecord.corpIMCRNumber
      },
      {
        name: 'costOfQualityStatus',
        section: 5,
        valid: Validate.numberRequired('costOfQualityStatus', newRecord.costOfQualityStatus).isValid,
        value: newRecord.costOfQualityStatus
      },
      {
        name: 'costOfQuality',
        section: 5,
        valid: Validate.numberRequired('costOfQuality', newRecord.costOfQuality).isValid,
        value: newRecord.costOfQuality
      },
      {
        name: 'imcrIatClosedDate',
        section: 5,
        valid: Validate.date('imcrIatClosedDate', newRecord.imcrIatClosedDate).isValid,
        value: newRecord.imcrIatClosedDate
      },
      // section 6
      {
        name: 'rcaChoiceId',
        section: 6,
        valid: Validate.numberRequired('rcaChoiceId', newRecord.rcaChoiceId).isValid,
        value: newRecord.rcaChoiceId
      },
      {
        name: 'rcaDueDate',
        section: 6,
        valid: Validate.date('rcaDueDate', newRecord.rcaDueDate).isValid,
        value: newRecord.rcaDueDate
      },
      {
        name: 'capChoiceId',
        section: 6,
        valid: Validate.numberRequired('capChoiceId', newRecord.capChoiceId).isValid,
        value: newRecord.capChoiceId
      },
      {
        name: 'capRecordsReceivedDate',
        section: 6,
        valid: Validate.date('capRecordsReceivedDate', newRecord.capRecordsReceivedDate).isValid,
        value: newRecord.capRecordsReceivedDate
      },
      {
        name: 'lessonsLearnedChoiceId',
        section: 6,
        valid: Validate.numberRequired('lessonsLearnedChoiceId', newRecord.lessonsLearnedChoiceId).isValid,
        value: newRecord.lessonsLearnedChoiceId
      },
      {
        name: 'capValidationChoiceId',
        section: 6,
        valid: Validate.numberRequired('capValidationChoiceId', newRecord.capValidationChoiceId).isValid,
        value: newRecord.capValidationChoiceId
      },
      // section 7
      {
        name: 'ownershipIMProvidedTheFollowingInstructions',
        section: 7,
        valid: Validate.required('ownershipIMProvidedTheFollowingInstructions', newRecord.ownershipIMProvidedTheFollowingInstructions).isValid,
        value: newRecord.ownershipIMProvidedTheFollowingInstructions
      },
      {
        name: 'otherComments',
        section: 7,
        valid: Validate.required('otherComments', newRecord.otherComments).isValid,
        value: newRecord.otherComments
      },
    ]

    const _errors = _validated.reduce((s, v) => {
      if (v.valid) {
        s[v.name] = undefined
        return s
      }
      // everything is invalid now
      const isBlank = (v.value === '' || v.value === undefined || v.value === null)
      // dirty, required, and blank
      if (dirty[v.name] && v.required && isBlank) {
        s[v.name] = {
          error: true,
          helperText: 'This field is required'
        }
        return s
      }
      // dirty, required, poopulated
      if (dirty[v.name] && v.required) {
        s[v.name] = {
          error: true,
          helperText: 'Invalid value'
        }
      }
      // dirty, not required, populated
      if (dirty[v.name] && !v.required && !isBlank) {
        s[v.name] = {
          error: true,
          helperText: 'Invalid value'
        }
      }
      return s
    }, {})

    setErrors(_errors)

    const invalidOwnershipEntries = _validated.filter(f => !f.valid && f.section === 3)
    const invalidBasicEntries = _validated.filter(f => !f.valid && f.section === 1 && ['title', 'description', 'eventDate', 'issueTypeId'].includes(f.name))
    const invalidRequiredEntries = [...invalidOwnershipEntries, ...invalidBasicEntries]

    setValidatedFields(_validated.filter(f => f.valid))

    let valid = false
    if (invalidRequiredEntries.length) {
      setIsValid(false)
      const msgs = []
      if (invalidBasicEntries.length) msgs.push('Please complete section 1 - Basic Info')
      if (invalidOwnershipEntries.length) msgs.push('Please complete section 3 - Affected Ownership(s)')
      setValidationMessage(msgs.map(m => <span>{m}<br /></span>))
    } else {
      setIsValid(true)
      valid = true
    }
    return [valid, _validated]
  }, [dirty, newRecord, products, ownerships])

  useEffect(() => {
    validateNewForm()
  }, [newRecord, products, ownerships])

  const formateDate = (date) => {
    if (!date) return
    if (date?.$isDayjsObject) return date?.$d.toISOString()
    if (typeof date === 'string') return date
  }

  const onDelete = () => {
    (async () => {
      setIsLoading(true)
      setDeleteDialogVisible(false)
      navigate('/incident-management/list')
      let submissionSuccessful = true
      let submissionError = {}
      try {
        const token = await getToken()
        const path = `${ApiGateway.imLogs}/${imLogId}`
        const options = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.idToken}`
          }
        }
        const result = await axios.delete(path, options)

      } catch (err) {
        if (err?.response) {
          submissionSuccessful = false
          submissionError = err.response.data
        } else if (err?.request) {
          submissionSuccessful = false
          submissionError = { message: err.message }
        } else {
          submissionSuccessful = false
          submissionError = { message: err.message }
        }
      }

      setIsLoading(false)
      const message = submissionSuccessful ? 'IM Log deleted successfully.' : `Oops, something went wrong: ${submissionError.message}`
      navigate(`/incident-management/list`, {
        state: { submissionSuccessful, submissionMessage: message }
      })

    })()
  }

  const onSave = useCallback(() => {
    const [_isValid, validated] = validateNewForm()
    if (!_isValid) {
      setErrors(validated.reduce((_errors, v) => {
        const isDirty = dirty[v.name] || false
        const isRequired = v.required || false
        const value = v.value
        if(isRequired && !value) {
          _errors[v.name] = {
            error: true,
            helperText: 'This field is required',
          }
        }
        if((isRequired && value && !v.valid) || (!isRequired && isDirty && !v.valid)) {
          _errors[v.name] = {
            error: true,
            helperText: 'Invalid value',
          }
        }
        return _errors
      }, {}))
      bus.emit(Event.ALERT, {
        "jsx": <span>{validationMessage}</span>,
        "style": "error",
        "duration": 500000,
        'showClose': true
      })
      return
    }
    (async () => {
      setIsLoading(true)
      let submissionSuccessful = true
      let submissionError = {}
      try {
        const imLog = {
          ...newRecord,
          products,
          ownerships
        }
        imLog.eventDate = formateDate(imLog.eventDate)
        imLog.dateNotified = formateDate(imLog.dateNotified)
        imLog.rcaDueDate = formateDate(imLog.rcaDueDate)
        imLog.imcrIatClosedDate = formateDate(imLog.imcrIatClosedDate)
        imLog.capRecordsReceivedDate = formateDate(imLog.capRecordsReceivedDate)
        const token = await getToken()
        const basePath = ApiGateway.imLogs
        const options = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.idToken}`
          }
        }
        let result
        if (params.id) {
          result = await axios.put(basePath, imLog, options)
        } else {
          result = await axios.post(basePath, imLog, options)
        }
      } catch (err) {
        if (err?.response) {
          submissionSuccessful = false
          submissionError = err.response.data
        } else if (err?.request) {
          submissionSuccessful = false
          submissionError = { message: err.message }
        } else {
          submissionSuccessful = false
          submissionError = { message: err.message }
        }
      }
      setIsLoading(false)
      navigate(`/incident-management/list`, {
        state: { submissionSuccessful, submissionError }
      })
    })()
  }, [newRecord, validateNewForm, ownerships, products, validationMessage, navigate, params.id, dirty])

  const confirmCancel = () => {
    if (Object.keys(dirty).length) { showDialog() }
    else handleCancel()
  }

  const handleCancel = () => {
    navigate(`/incident-management/list`)
  }

  const [confirmDialog, setConfirmDialog] = useState(false)

  const showDialog = () => {
    setConfirmDialog(true)
  }

  const hideDialog = () => {
    setConfirmDialog(false)
  }

  const loadEventTypes = async () => {
    const token = await getToken()

    const basePath = `${ApiGateway.imLogs}/event-types/search`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const search = {}

    const response = await axios.post(basePath, search, options)
    if (response.data) {
      const { results } = response.data
      setEventTypeList(results)
    }
  }

  const loadIssueTypes = async () => {
    const token = await getToken()

    const basePath = `${ApiGateway.imLogs}/issue-types/search`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const search = {}

    const response = await axios.post(basePath, search, options)
    if (response.data) {
      const { results } = response.data
      setIssueTypeList(results)
    }
  }

  const loadUsers = async (imLog) => {
    if (imLog.managedBy) {
      const managedByResults = await getUserByTokens([imLog.managedBy])
      if (managedByResults?.length) {
        setManagedBySelected(managedByResults[0])
      }
    }
    if (imLog.governanceManagerAssigned) {
      const governanceManagerAssignedResults = await getUserByTokens([imLog.governanceManagerAssigned])
      if (governanceManagerAssignedResults?.length) {
        setGovernanceManagerAssignedSelected(governanceManagerAssignedResults[0])
      }
    }

  }

  const loadImLog = async () => {
    const token = await getToken()

    const basePath = `${ApiGateway.imLogs}/search`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const search = {
      id: imLogId
    }

    const response = await axios.post(basePath, search, options)
    if (response.data) {
      const { results } = response.data

      if (results && results.length) {
        const imLog = results[0]

        await loadUsers(imLog)
        setNewRecord(imLog)
        setProducts(imLog.AffectedProducts)
        setOwnerships([...imLog?.AffectedOwnerships])
      }
    }
  }

  const isFacilityBottler = roleDetails.hasRole([ROLES.BOTTLER])
  const isCorpBottler = roleDetails.hasRole([ROLES.BOTTLER_CORP])
  const isImViewer = roleDetails.hasRole([ROLES.IM_VIEWER, ROLES.QSE_MGR])
  const isBottler = isFacilityBottler || isCorpBottler
  const isManager = roleDetails.hasRole([ROLES.IM_MANAGER, ROLES.ADMIN, ROLES.MFI_ADMIN])
  const isAnyone = isBottler || isManager || isImViewer

  const breadcrumb = <div><span><HealthAndSafety />&nbsp;{props.readOnly ? 'View' : imLogId ? 'Edit' : 'Create'}&nbsp;Incident</span></div>

  useEffect(() => {
    if (!params.id && location?.state?.page === 'IMLogFormPage') {
      setNewRecord(location?.state?.newRecord)
      setEventTypeList(location?.state?.eventTypeList)
      setIssueTypeList(location?.state?.issueTypeList)
      setManagedByList(location?.state?.managedByList)
      setManagedBySearch(location?.state?.managedBySearch)
      setManagedBySelected(location?.state?.managedBySelected)
      setGovernanceManagerAssignedList(location?.state?.governanceManagerAssignedList)
      setGovernanceManagerAssignedSearch(location?.state?.governanceManagerAssignedSearch)
      setGovernanceManagerAssignedSelected(location?.state?.governanceManagerAssignedSelected)
      setProductionCodes(location?.state?.productionCodes)
      setProducts(location?.state?.products)
      setOwnerships(location?.state?.ownerships)
    }
  }, [location?.state, params.id])

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      if (!location?.state?.page) {
        const promises = [
          loadEventTypes(),
          loadIssueTypes(),
        ]
        if (imLogId) {
          promises.push(loadImLog())
        }
        await Promise.all(promises)
      }
      setIsLoading(false)
    })()
  }, [])

  const itemSizes = {
    mobile: 12,
    desktop: 3.75
  }
  const gridItemProps = {
    item: true,
    xs: itemSizes.mobile,
    sm: itemSizes.mobile,
    md: itemSizes.desktop,
    lg: itemSizes.desktop,
    xl: itemSizes.desktop
  }

  const twoThirdsGridItemProps = {
    item: true,
    xs: 7.5,
    sm: 7.5,
    md: 7.5,
    lg: 7.5,
    xl: 7.5,
  }


  const fwGridItemProps = {
    item: true,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
  }

  const handleChange = (e, __newRecord) => {
    const id = e.target.id || e.target.name
    const _newRecord = __newRecord || { ...newRecord }
    _newRecord[id] = e.target.value
    setNewRecord(_newRecord)
  }

  const handleDateChange = (id, date) => {
    const _newRecord = { ...newRecord }
    _newRecord[id] = dayjs(date)
    setNewRecord(_newRecord)
  }


  const getUserByTokens = async (tokens = [], requestConfig) => {
    const cleanTokens = tokens.filter(t => t !== 'undefined' && t !== undefined && t !== '' && t)
    if (cleanTokens.length === 0) return []

    if (!requestConfig) {
      requestConfig = await getRequestConfig()
    }
    const basePath = `${ApiGateway.user}/search/advanced`
    try {
      const response = await axios.post(basePath, { tokens: cleanTokens }, requestConfig)
      return response.data
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    debounce(() => {
      if (managedBySearch) {
        const tokens = managedBySearch.replace(/[\(\)]/g, '').split(' ')
        getUserByTokens(tokens)
          .then(users => {
            setManagedByList(users)
          })
          .catch(err => {
            console.error(err)
          })
      }
    }, 1000)()
  }, [managedBySearch])

  useEffect(() => {
    debounce(() => {
      if (governanceManagerAssignedSearch) {
        const tokens = governanceManagerAssignedSearch.replace(/[\(\)]/g, '').split(' ')
        getUserByTokens(tokens)
          .then(users => {
            setGovernanceManagerAssignedList(users)
          })
          .catch(err => {
            console.error(err)
          })
      }
    }, 400)()
  }, [governanceManagerAssignedSearch])

  const loadingExistingImLog = useMemo(() => {
    return isLoading
  }, [isLoading])

  const ownershipSelections = useMemo(() => (ownerships || []).map((o, i) => {
    return <OwnershipSelection
      key={i}
      disabled={props.readOnly}
      title={`Ownership ${i + 1}`}
      defaultExpanded={i === 0}
      isAnyone={isAnyone}
      gridItemProps={gridItemProps}
      fwGridItemProps={fwGridItemProps}
      loadingExistingImLog={isLoading}
      setValues={(values) => {
        const _ownerships = [...ownerships]
        _ownerships[i] = values
        setOwnerships(_ownerships)
        const _dirty = structuredClone(dirty)
        _dirty.ownerships = _ownerships
        setDirty(_dirty)
      }}
      remove={() => {
        const _ownerships = [...ownerships]
        _ownerships.splice(i, 1)
        setOwnerships(_ownerships)
      }}
      startingValues={o}
      isFacilityBottler={isFacilityBottler}
      isCorpBottler={isCorpBottler}
    />
  }), [ownerships, isLoading])

  const productSelections = useMemo(() => (products || []).map((p, i) => {
    return <ProductSelection
      key={i}
      disabled={props.readOnly}
      title={`Product ${i + 1}`}
      defaultExpanded={i === 0}
      isAnyone={isAnyone}
      gridItemProps={gridItemProps}
      fwGridItemProps={fwGridItemProps}
      loadingExistingImLog={isLoading}
      setValues={(values) => {
        const _products = [...products]
        _products[i] = values
        setProducts(_products)
        const _dirty = structuredClone(dirty)
        _dirty.products = _products
        setDirty(_dirty)
      }}
      remove={() => {
        const _products = [...products]
        _products.splice(i, 1)
        setProducts(_products)
      }}
      startingValues={p}
    />
  }), [products, isLoading])

  useEffect(() => {
    validateNewForm()
  }, [validateNewForm, dirty])

  const handleBlur = (e) => {
    const id = e.target.id || e.target.name
    const _dirty = structuredClone(dirty)
    _dirty[id] = true
    setDirty(_dirty)
  }

  const errorProps = useCallback((id, required) => {
    if(!dirty[id] && !required) return {
      error: false
    }
    return {
      error: errors[id]?.error || false,
      helperText: errors[id]?.helperText,
    }
  }, [errors, dirty])

  return (
    <>
      <Page pageClassName="im-log-form-page" {...props} breadcrumb={breadcrumb} isLoading={isLoading} commandBar={
        <FormViewCommandBar onSave={onSave} onCancel={confirmCancel}
          loading={isLoading}
          helpPage="/incident-management/help"
          helpPageState={{
            backTo: imLogId ? `/incident-management/edit/${imLogId}` : '/incident-management/create',
            page: 'IMLogFormPage',
            newRecord,
            eventTypeList,
            issueTypeList,
            managedByList,
            managedBySearch,
            managedBySelected,
            governanceManagerAssignedList,
            governanceManagerAssignedSearch,
            governanceManagerAssignedSelected,
            productionCodes,
            products,
            ownerships,
          }}
          enableSave={Object.keys(dirty).length > 0}
          showSave={isManager || isBottler}
          showClose={false}
          showCancel={true}
        />}>

        <ConfirmDialog title="Discard all changes?" text="You will not be able to recover your work." open={confirmDialog} hideDialog={hideDialog} handleConfirm={handleCancel} yesLabel="Discard" noLabel="Stay on Page" />
        <FormCard
          title="1. Basic Info"
          defaultExpanded={true}
          showProgress={!isLoading && !loadingExistingImLog}
          completedFields={(validatedFields || []).filter(f => f.section === 1).length}
          totalFields={isBottler ? 4 : 6}
        >

          <FormElement
            show={isAnyone}
            gridItemProps={gridItemProps}
            loading={loadingExistingImLog}
          >
            <TextField
              id="title"
              size='small'
              disabled={props.readOnly}
              value={newRecord.title}
              onChange={(e) => handleChange(e)}
              label="Incident Title"
              fullWidth
              onBlur={handleBlur}
              {...errorProps('title', true)}
            />
          </FormElement>

          <FormElement
            show={isAnyone}
            gridItemProps={twoThirdsGridItemProps}
            loading={loadingExistingImLog}
          >
            <TextField
              id="description"
              disabled={props.readOnly}
              size='small'
              value={newRecord.description}
              onChange={(e) => handleChange(e)}
              label="Description"
              multiline
              fullWidth
              onBlur={handleBlur}
              {...errorProps('description', true)}
            />
          </FormElement>

          <FormElement
            show={isAnyone}
            gridItemProps={gridItemProps}
            loading={loadingExistingImLog}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id='eventDate'
                disabled={props.readOnly}
                format="MM/DD/YYYY"
                disableFuture
                label="Event Date"
                value={dayjs(newRecord?.eventDate?.['$d'] || newRecord?.eventDate)}
                onChange={(date) => handleDateChange('eventDate', date)}
                slotProps={{
                  textField: {
                    id: 'eventDate',
                    size: 'small',
                    onBlur: handleBlur,
                    ...errorProps('eventDate', true)
                  },
                }}
              />
            </LocalizationProvider>
          </FormElement>


          <FormElement show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <InputLabel size='small'>Event Type</InputLabel>
            <Select
              name="eventTypeId"
              disabled={props.readOnly}
              size='small'
              value={`${newRecord.eventTypeId}`}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
              {...errorProps('eventTypeId')}
            >
              {eventTypeList.map((eventType, i) =>
                <MenuItem key={i} value={`${eventType.eventTypeId}`}>{eventType.label}</MenuItem>
              )}
            </Select>
          </FormElement>

          <FormElement show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <InputLabel size='small'>Event Status</InputLabel>
            <Select
              name="eventStatus"
              disabled={props.readOnly}
              size='small'
              value={`${newRecord.eventStatus}`}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
              inputProps={{
                ...errorProps('eventStatus')
              }}
            >
              {eventStatuses.map((eventStatus, i) =>
                <MenuItem key={i} value={`${eventStatus}`}>{eventStatus}</MenuItem>
              )}
            </Select>
          </FormElement>

          <FormElement show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <InputLabel size='small'>Issue Type</InputLabel>
            <Select
              name="issueTypeId"
              disabled={props.readOnly}
              size='small'
              value={`${newRecord.issueTypeId}`}
              onChange={(e) => {
                const _newRecord = { ...newRecord }
                _newRecord.internalCause = undefined
                handleChange(e, _newRecord)
              }}
              {...errorProps('issueTypeId', true)}
              onBlur={handleBlur}
            >
              {issueTypeList.map((issueType, i) =>
                <MenuItem key={i} value={`${issueType.issueTypeId}`}>{issueType.label}</MenuItem>
              )}
            </Select>
            <FormHelperText {...errorProps('issueTypeId')}>{errorProps('issueTypeId').helperText}</FormHelperText>
          </FormElement>

          {`${newRecord.issueTypeId}` === '7' && <FormElement show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <FormControlLabel
              control={<Checkbox
                disabled={props.readOnly}
                size='small'
                checked={newRecord.internalCause || false}
                onChange={(e) => {
                  const { checked } = e.target
                  handleChange({ target: { value: checked, id: 'internalCause' } })
                }}
              />}
              label="Internal Cause?"
            />
          </FormElement>}

        </FormCard>

        <FormCard
          title="2. Submission"
          showProgress={!isLoading && !loadingExistingImLog}
          completedFields={validatedFields.filter(f => f.section === 2).length}
          totalFields={isBottler ? 5 : 5}
        >

          <FormElement show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id='dateNotified'
                disabled={props.readOnly}
                format="MM/DD/YYYY"
                disableFuture
                label="Date Notified"
                value={dayjs(newRecord?.dateNotified?.['$d'] || newRecord?.dateNotified)}
                onChange={(date) => handleDateChange('dateNotified', date)}
                slotProps={{
                  textField: {
                    id: 'dateNotified',
                    size: 'small',
                    onBlur: handleBlur,
                    ...errorProps('dateNotified')
                  },
                }}
              />
            </LocalizationProvider>
          </FormElement>


          <FormElement show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <TextField
              id="notifiedBy"
              disabled={props.readOnly}
              size='small'
              value={newRecord.notifiedBy}
              onChange={(e) => handleChange(e)}
              label="Notified By"
              fullWidth
              onBlur={handleBlur}
              {...errorProps('notifiedBy')}
            />
          </FormElement>


          <FormElement show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <TextField
              id="submitterName"
              disabled={props.readOnly}
              size='small'
              value={newRecord.submitterName}
              onChange={(e) => handleChange(e)}
              label="Submitter Name"
              fullWidth
              onBlur={handleBlur}
              {...errorProps('submitterName')}
            />
          </FormElement>

          <FormElement show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <TextField
              id="submitterEmail"
              disabled={props.readOnly}
              size='small'
              value={newRecord.submitterEmail}
              onChange={(e) => handleChange(e)}
              label="Submitter Email"
              fullWidth
              onBlur={handleBlur}
              {...errorProps('submitterEmail')}
            />
          </FormElement>

          <FormElement show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <PhoneInput
              id="submitterPhoneNumber"
              disabled={props.readOnly}
              size='small'
              value={newRecord.submitterPhoneNumber}
              onChange={handleChange}
              label="Submitter Phone Number"
              onBlur={handleBlur}
              {...errorProps('submitterPhoneNumber')}
              fullWidth
            />
          </FormElement>
        </FormCard>

        <FormCard
          title="3. Affected Ownership(s)"
          showProgress={!isLoading && !loadingExistingImLog}
          completedFields={validatedFields.filter(f => f.section === 3).length}
          totalFields={ownerships.reduce((s, x) => {
            if (x.otherOwnership) {
              return s + 1
            }
            return s + 2
          }, 0)}
          spacing={0}
          rowGap={0}
        >
          {ownershipSelections}
          {!isFacilityBottler && !props.readOnly && <Button
            style={{ marginLeft: '10px' }}
            onClick={() => {
              const _ownerships = [...ownerships, {}]
              setOwnerships(_ownerships)
            }}
            size="small">
            Add Ownership
            <Add size="small" />
          </Button>}
        </FormCard>

        <FormCard
          title="4. Affected Product(s)"
          showProgress={!isLoading && !loadingExistingImLog}
          completedFields={validatedFields.filter(f => f.section === 4).length}
          totalFields={products.reduce((s, x) => {
            if (x.otherProduct) {
              return s + 1
            }
            return s + 9
          }, 0)}
          spacing={0}
          rowGap={0}
        >
          {productSelections}
          {!props.readOnly && <Button
            style={{ marginLeft: '10px' }}
            onClick={() => {
              const _products = [...products, {}]
              setProducts(_products)
            }}
            size="small">
            Add Product
            <Add size="small" />
          </Button>}

        </FormCard>

        {!isBottler && <FormCard
          title="5. IM Manager"
          showProgress={!isLoading && !loadingExistingImLog}
          completedFields={validatedFields.filter(f => f.section === 5).length}
          totalFields={newRecord.costOfQualityStatus + '' === '3' ? 6 : 5}
        >

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <Autocomplete
              id="managedBy"
              disabled={props.readOnly}
              size='small'
              getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName} (${option.koId})` : ""}
              filterOptions={(x) => x}
              options={managedByList}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={managedBySelected}
              noOptionsText="No results"
              onBlur={handleBlur}
              {...errorProps('managedBy')}
              onChange={(event, newValue) => {
                const _newRecord = { ...newRecord }
                _newRecord.managedBy = newValue?.koId
                setManagedBySelected(newValue)
                setNewRecord(_newRecord)
                setManagedBySearch(`${newValue?.firstName} ${newValue?.lastName} (${newValue?.koId})`)

              }}
              onInputChange={(event, newInputValue) => {
                setManagedBySearch(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Managed By" fullWidth />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        <Typography variant="body2" color="text.secondary">
                          {option.firstName} {option.lastName} ({option.koId})
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </FormElement>

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <Autocomplete
              id="governanceManagerAssigned"
              disabled={props.readOnly}
              size='small'
              getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName} (${option.koId})` : ""}
              filterOptions={(x) => x}
              options={governanceManagerAssignedList}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={governanceManagerAssignedSelected}
              noOptionsText="No results"
              onBlur={handleBlur}
              {...errorProps('governanceManagerAssigned')}
              onChange={(event, newValue) => {
                const _newRecord = { ...newRecord }
                _newRecord.governanceManagerAssigned = newValue?.koId
                setGovernanceManagerAssignedSelected(newValue)
                setNewRecord(_newRecord)
                setGovernanceManagerAssignedSearch(`${newValue?.firstName} ${newValue?.lastName} (${newValue?.koId})`)

              }}
              onInputChange={(event, newInputValue) => {
                setGovernanceManagerAssignedSearch(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Governance Mgr Assigned" fullWidth />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        <Typography variant="body2" color="text.secondary">
                          {option.firstName} {option.lastName} ({option.koId})
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </FormElement>

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <TextField
              id="corpIMCRNumber"
              disabled={props.readOnly}
              size='small'
              type='number'
              value={newRecord.corpIMCRNumber}
              onChange={(e) => handleChange(e)}
              label="Corp IMCR Number"
              fullWidth
              onBlur={handleBlur}
              {...errorProps('corpIMCRNumber')}
            />
          </FormElement>



          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <InputLabel>Cost Of Quality</InputLabel>
            <Select
              name="costOfQualityStatus"
              disabled={props.readOnly}
              value={`${newRecord.costOfQualityStatus}`}
              onChange={(e) => {
                handleChange(e, { ...newRecord, costOfQuality: undefined })
              }}
              onBlur={handleBlur}
              {...errorProps('costOfQualityStatus')}
            >
              {coqStatuses.map((coqStatus, i) =>
                <MenuItem key={i} value={`${coqStatus.id}`}>{coqStatus.label}</MenuItem>
              )}
            </Select>
          </FormElement>

          <FormElement size='small' show={newRecord.costOfQualityStatus + '' === '3' && isManager} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <TextField
              id="costOfQuality"
              disabled={props.readOnly}
              size='small'
              value={newRecord.costOfQuality}
              onChange={(e) => handleChange(e)}
              label="Cost of Quality Amount"
              type='number'
              fullWidth
              onBlur={handleBlur}
              {...errorProps('costOfQuality')}
            />
          </FormElement>

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id='imcrIatClosedDate'
                disabled={props.readOnly}
                format="MM/DD/YYYY"
                disableFuture
                label="IMCR/IAT Closed Date"
                value={dayjs(newRecord?.imcrIatClosedDate?.['$d'] || newRecord?.imcrIatClosedDate)}
                onChange={(date) => handleDateChange('imcrIatClosedDate', date)}
                slotProps={{
                  textField: {
                    id: 'imcrIatClosedDate',
                    size: 'small',
                    onBlur: handleBlur,
                    ...errorProps('eventTypeId')
                  },
                }}
              />
            </LocalizationProvider>
          </FormElement>


        </FormCard>}

        {!isBottler && <FormCard
          title="6. Mitigation"
          showProgress={!isLoading && !loadingExistingImLog}
          completedFields={validatedFields.filter(f => f.section === 6).length}
          totalFields={6}
        >

          {/* bucket RCA, CAP, and Lessons Learned in a card */}
          {/* 7 day calculation past event date if not received */}
          <Grid show={isManager || props.readOnly} xs={2} sx={{ padding: '10px' }}>
            <Typography variant="body2" color="text.secondary">CAP Overdue?</Typography>
            {newRecord.capOverdue && <div style={{ display: 'flex' }}>
              <AssignmentLate color='error' />&nbsp;YES
            </div>}
            {!newRecord.capOverdue && <div style={{ display: 'flex' }}>
              <Done color='success' />&nbsp;NO
            </div>}
          </Grid>

          <Grid show={isManager || props.readOnly} xs={8} sx={{ padding: '10px' }}>
            <Typography variant="body2" color="text.secondary">RCA Overdue?</Typography>
            {newRecord.rcaOverdue && <div style={{ display: 'flex' }}>
              <AssignmentLate color='error' />&nbsp;YES
            </div>}
            {!newRecord.rcaOverdue && <div style={{ display: 'flex' }}>
              <Done color='success' />&nbsp;NO
            </div>}
          </Grid>

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <InputLabel size='small'>RCA</InputLabel>
            <Select
              name="rcaChoiceId"
              disabled={props.readOnly}
              size='small'
              value={`${newRecord.rcaChoiceId}`}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
              {...errorProps('rcaChoiceId')}
            >
              {rcaCapChoices.map((rcaCapChoice, i) =>
                <MenuItem key={i} value={`${rcaCapChoice.id}`}>{rcaCapChoice.label}</MenuItem>
              )}
            </Select>
          </FormElement>

          {/* 7 day calculation beyond event date */}
          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id='rcaDueDate'
                disabled={props.readOnly}
                format="MM/DD/YYYY"
                label="RCA Due Date"
                value={dayjs(newRecord?.rcaDueDate?.['$d'] || newRecord?.rcaDueDate)}
                onChange={(date) => handleDateChange('rcaDueDate', date)}
                slotProps={{
                  textField: {
                    id: 'rcaDueDate',
                    size: 'small',
                    onBlur: handleBlur,
                    ...errorProps('rcaDueDate')
                  },
                }}
              />
            </LocalizationProvider>
          </FormElement>

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <InputLabel size='small'>CAP</InputLabel>
            <Select
              name="capChoiceId"
              disabled={props.readOnly}
              size='small'
              value={`${newRecord.capChoiceId}`}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
              {...errorProps('capChoiceId')}
            >
              {rcaCapChoices.map((rcaCapChoice, i) =>
                <MenuItem key={i} value={`${rcaCapChoice.id}`}>{rcaCapChoice.label}</MenuItem>
              )}
            </Select>
          </FormElement>

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id='capRecordsReceivedDate'
                disabled={props.readOnly}
                format="MM/DD/YYYY"
                disableFuture
                label="CAP Records Received Date"
                value={dayjs(newRecord?.capRecordsReceivedDate?.['$d'] || newRecord?.capRecordsReceivedDate)}
                onChange={(date) => handleDateChange('capRecordsReceivedDate', date)}
                slotProps={{
                  textField: {
                    id: 'capRecordsReceivedDate',
                    size: 'small',
                    onBlur: handleBlur,
                    ...errorProps('capRecordsReceivedDate')
                  },
                }}
              />
            </LocalizationProvider>
          </FormElement>

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <InputLabel size='small'>Lessons Learned</InputLabel>
            <Select
              name="lessonsLearnedChoiceId"
              disabled={props.readOnly}
              size='small'
              value={`${newRecord.lessonsLearnedChoiceId}`}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
              {...errorProps('lessonsLearnedChoiceId')}
            >
              {lessonsLearnedChoices.map((lessonsLearnedChoice, i) =>
                <MenuItem key={i} value={lessonsLearnedChoice.id}>{lessonsLearnedChoice.label}</MenuItem>
              )}
            </Select>
          </FormElement>

          <FormElement size='small' show={isManager || props.readOnly} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <InputLabel size='small'>CAP Validation</InputLabel>
            <Select
              name="capValidationChoiceId"
              disabled={props.readOnly}
              size='small'
              value={`${newRecord.capValidationChoiceId}`}
              onChange={(e) => handleChange(e)}
              onBlur={handleBlur}
              {...errorProps('capValidationChoiceId')}
            >
              {capValidationChoices.map((capValidationChoice, i) =>
                <MenuItem key={i} value={capValidationChoice.id}>{capValidationChoice.label}</MenuItem>
              )}
            </Select>
          </FormElement>

        </FormCard>}

        <FormCard
          title={`${isBottler ? '5' : '7'}. Comments`}
          showProgress={!isLoading && !loadingExistingImLog}
          completedFields={validatedFields.filter(f => f.section === 7).length}
          totalFields={2}
        >

          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <TextField
              id="ownershipIMProvidedTheFollowingInstructions"
              disabled={props.readOnly}
              size='small'
              multiline
              value={newRecord.ownershipIMProvidedTheFollowingInstructions}
              onChange={(e) => handleChange(e)}
              label="Ownership IM provided the following instructions"
              fullWidth
              onBlur={handleBlur}
              {...errorProps('ownershipIMProvidedTheFollowingInstructions')}
            />
          </FormElement>

          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <TextField
              id="otherComments"
              disabled={props.readOnly}
              size='small'
              multiline
              value={newRecord.otherComments}
              onChange={(e) => handleChange(e)}
              label="Other Comments"
              fullWidth
              onBlur={handleBlur}
              {...errorProps('otherComments')}
            />
          </FormElement>

        </FormCard>

        {isManager && imLogId && <>
          <ConfirmDialog title="Delete this record?" text="This action cannot be undone. Clicking DELETE will immediately delete this record." open={deleteDialogVisible} hideDialog={() => setDeleteDialogVisible(false)} handleConfirm={onDelete} yesLabel="Delete" noLabel="Cancel" />
          <Button
            style={{ margin: "10px", marginBottom: '40px', float: 'right' }}
            color='error' variant="contained"
            onClick={() => setDeleteDialogVisible(true)}
          >
            Delete This Record
          </Button>
        </>}
        <br /><br />
      </Page >
    </>
  )

}