import { useEffect, useMemo, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridFooterContainer, GridFooter, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { Edit, Visibility } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2'
import { Link, useNavigate } from 'react-router-dom';
import { ApiGateway } from '../config/config.js'
import axios from 'axios'
import ConfirmDialog from '../form/ConfirmDialog.js'
import { ROLES } from '../auth/role.js'
import RoleContext from '../auth/RoleContext.js';
import { getRequestConfig } from '../auth/auth.js';
import { QUALITY_FS, ENV_SAFETY, titles, SELF_ASMNT, BU_TYPES, ASMT_STATUS } from '../utils/constants'
import { useContext } from 'react';
import { removeAllLocalStorageItemsStartingWith } from '../utils/storage.js'
import ReactDOM from 'react-dom';
import { serializeFilterToQueryString, serializeSortToQueryString } from '../utils/TableUtils.js';

export default function ListCPKSubmissions(props = {}) {
  let navigate = useNavigate();

  // Table state
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({})
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState([{field: "completedOn", sort: "desc"}]);
  const [rowCount, setRowCount] = useState(0);

  let mfrProfileId = props.mfrProfileId
  const setMfrProfileId = props.setMfrProfileId
  const [manufacturerProfileList, setManufacturerProfileList] = useState()
  const roleDetails = useContext(RoleContext)

  useEffect(() => {
  }, [filterModel])

  const getCPKSubmissionsList = async (_mfrProfileId, requestConfig) => {
    props.startLoading()


    const filterQuery = serializeFilterToQueryString(filterModel)
    const sortQuery = serializeSortToQueryString(sortModel)
    const offset = (paginationModel.page) * paginationModel.pageSize
    const limit = paginationModel.pageSize

    const path = `${ApiGateway.cpk}?_=${filterQuery}${sortQuery}&offset=${offset}&limit=${limit}`

    if (!requestConfig) requestConfig = await getRequestConfig()

    const { data } = await axios.get(path, requestConfig)
    const { results, count } = data

    const sortedResults = results.sort((a, b) => {
      return new Date(b.submissionDate).getTime() - new Date(a.submissionDate).getTime()
    })

    setCpkSubmissionRows(sortedResults)
    setRowCount(count || results.length)
    props.stopLoading()
    return
  }

  const ownershipFormatter = (cell) => {
    const ownershipId = cell?.value
    return ownershipId
  }

  const submittedDateFormatter = (cell) => {
    const submittedDate = cell?.value
    if(!submittedDate) return ''
    return submittedDate.split('-')
      .splice(0, 3)
      .join('-')
  }

  const completedOnFormatter = (cell) => {
    const completedOn = cell?.value
    if(!completedOn) return ''
    return completedOn.split('T')
      .join(' ')
      .split(':')
      .splice(0, 2)
      .join(':')
  }

  const columns = useMemo(() => [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: (params) => [
        <GridActionsCellItem icon={<Edit />} onClick={(e, row) => {
          navigate(`/process-capability-submissions/view/${params.row.id}`)
        }} label="Edit" />
      ]
    },
    { field: 'id', headerName: 'ID', width: 300 },
    { field: 'ownershipName', headerName: 'Ownership', width: 200 },
    { field: 'manufacturerName', headerName: 'Manufacturer', width: 200 },
    { field: 'submitter', headerName: 'Submitter', width: 200 },
    { field: 'email', headerName: 'Submitter Email', width: 200 },
    { field: 'submissionDate', headerName: 'Submission Date', width: 200, valueFormatter: submittedDateFormatter, type: 'date'},
    { field: 'completedOn', headerName: 'Entered On', width: 200, filterable: false, valueFormatter: completedOnFormatter, type: 'date' },
  ])
  const [cpkSubmissionRows, setCpkSubmissionRows] = useState([])

  const initializePage = async () => {
    const requestConfig = await getRequestConfig()
    const promises = []
    // if (mfrProfileId) {
    promises.push(getCPKSubmissionsList(mfrProfileId, requestConfig))
    // }

    props.startLoading()
    await Promise.all([
      // getManufacturerProfilesList(requestConfig),
      ...promises
    ])
    props.stopLoading()
  }

  useEffect(() => {
    initializePage()
  }, [
    paginationModel,
    sortModel,
    filterModel
  ])

  const onPaginationModelChange = (_paginationModel) => {
    setPaginationModel(_paginationModel)
  }

  const onPageSizeChange = (newPageSize) => {
    setPaginationModel(Object.assign({}, paginationModel, { pageSize: newPageSize }))
  }

  const onPageChange = (newPage) => {
    setPaginationModel(Object.assign({}, paginationModel, { page: newPage }))
  }

  const onFilterModelChange = (_filterModel) => {
    setFilterModel(_filterModel)
  }

  const onSortModelChange = (_sortModel) => {
    setSortModel(_sortModel)
  }

  const getInitialColumnVisibility = () => {
    const initialColumnVisibility = {}
    columns.forEach((column) => {
      const { field } = column
      const prefix = `IMLogColumnVisibility-${field}`
      const value = localStorage.getItem(prefix)
      if (value !== undefined && value !== null) {
        initialColumnVisibility[field] = value === 'true'
      }
    })
    if(Object.keys(initialColumnVisibility).length === 0) {
      return {
        id: false,
        ownershipName: true,
        manufacturerName: true,
        submitter: true,
        email: false,
        submissionDate: true,
        completedOn: true
      }
    }
    return initialColumnVisibility
  }

  useEffect(() => {
    setColumnVisibilityModel(getInitialColumnVisibility())
  }, [])

  function CustomFooter() {
    return (
      <GridFooterContainer
        sx={{
          justifyContent: 'start'
        }}
      >
        <GridFooter />
      </GridFooterContainer>
    );
  }


  return (
    <>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '96%', margin: '1em auto' }}>
        <Grid>
          <DataGrid
            className="imLogsDataGrid"
            onPageSizeChange={onPageSizeChange}
            // density='compact'
            filterMode="server"
            rows={cpkSubmissionRows}
            columns={columns}
            autoHeight={true}
            rowCount={rowCount}
            onColumnVisibilityModelChange={(newModel) => {
              if (Object.keys(newModel).length === 0) {
                removeAllLocalStorageItemsStartingWith('IMLogColumnVisibility-')
              }
              for (const field in newModel) {
                const value = newModel[field]
                const prefix = `IMLogColumnVisibility-${field}`
                localStorage.setItem(`${prefix}`, value)
              }

              setColumnVisibilityModel(newModel)
            }}
            columnVisibilityModel={columnVisibilityModel}
            initialState={{
              pagination: {
                paginationModel
              },
              columns: {
                columnVisibilityModel
              },
              sorting: {
                sortModel
              }
            }}
            pageSize={paginationModel.pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={onPaginationModelChange}
            onPageChange={onPageChange}
            onFilterModelChange={onFilterModelChange}
            onSortModelChange={onSortModelChange}
            sortModel={sortModel}
            slots={{ toolbar: GridToolbar }}
            components={{ Footer: CustomFooter }}
          />
        </Grid>
      </Paper>
    </>
  )
}