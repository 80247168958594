import { default as React, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import HelpIcon from '@mui/icons-material/Help'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom';
import EditOutlined from '@mui/icons-material/EditOutlined'
import { BugReport } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { ROLES } from '../auth/role';
import RoleContext from '../auth/RoleContext';
import { Stage } from '../config/config';

export default function FormViewCommandBar (props = {
  loading: false,
  showEdit: false,
  onEdit: undefined,
  showCancel: false,
  onCancel: undefined,
  showClose: false,
  onClose: undefined,
  showSave: false,
  enableSave: false,
  onSave: undefined,
  leftJsx: undefined,
  actionButtons: {
    label: '',
    onClick: undefined,
    endIcon: undefined,
    enabled: false,
    show: false
  }
}) {
  const navigate = useNavigate()
  const [saveButtonLabel, setSaveButtonLabel] = useState('Save')
  const [debugDialogOpen, setDebugDialogOpen] = useState(false)
  const toggleHelp = () => {
    navigate(props.helpPage, {state: props.helpPageState || {}})
  }

  const roleDetails = useContext(RoleContext)
  const isAdmin = roleDetails.hasRole([ROLES.ADMIN])

  return (
    <div className="tis-commandbar tis-commandbar-formview">
      <Dialog open={debugDialogOpen} onClose={() => setDebugDialogOpen(false)}>
        <textarea style={{width: '500px'}} rows={50} aria-multiline>{props.debug}</textarea>
      </Dialog>
      {props.leftJsx || undefined}
      {props.helpPage && <LoadingButton loading={props.loading} onClick={toggleHelp}><HelpIcon/></LoadingButton>}
      {props.actionButtons && props.actionButtons.length &&
        props.actionButtons.reduce((p, b) => {
          if (b.show) {
            p.push(
              <LoadingButton loading={props.loading} variant="contained" onClick={b.onClick} endIcon={b.endIcon || undefined} disabled={!b.enabled}>{b.label}</LoadingButton>
            )
          }
          return p
        },[])
      }
      {Stage === 'dev' && isAdmin && props.debug && <LoadingButton loading={props.loading} variant="outlined" onClick={() => setDebugDialogOpen(true)} endIcon={<BugReport />}>DEBUG</LoadingButton>}
      {props.showEdit && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onEdit} endIcon={<EditOutlined />}>Edit</LoadingButton>}
      {(props.showSave === undefined || props.showSave) && 
        <LoadingButton loading={props.loading} variant="contained" onClick={props.onSave} endIcon={<SaveIcon />} disabled={!props.enableSave }>{saveButtonLabel}</LoadingButton>
      }
      {(props.showCancel === undefined || props.showCancel) && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onCancel} endIcon={<CancelIcon />}>Cancel</LoadingButton>}
      {(props.showClose === undefined || props.showClose) && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onClose} endIcon={<CancelIcon />}>Close</LoadingButton>}
      {props.showBack && <LoadingButton loading={props.loading} variant="outlined" onClick={props.onBack} >Back</LoadingButton>}
    </div>
  )
}
