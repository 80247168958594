import { useContext, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
import { ASMT_STATUS } from '../../utils/constants'
import SummaryOfFindings from './SummaryOfFindings'
import SummaryOutcome from './SummaryOutcome'
import TotalFindings from './TotalFindings'

export default function LinearAssessmentSummary(props={includedModels:[], assessmentItems: [], assessment: undefined}) {
  const roleDetails = useContext(RoleContext)
  const assessment = props.assessment

  const showSummaryOfFindings = () => {
    return roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) || (assessment 
      && [ASMT_STATUS.COMPLETED, ASMT_STATUS.FINALIZED].includes(assessment.status))
  }

  return ( <>{ assessment && 
    <Grid container rowGap={2} margin={1}>

      {/* OUTCOME */}
      <Grid item xs={12}>
        <Typography variant='h6'>Outcome</Typography>
      </Grid>
      <Grid item xs={12}>
        <SummaryOutcome assessment={assessment} assessmentItems={props.assessmentItems} includedModels={props.includedModels} />
      </Grid>

      {/* ASSESSOR COMMENTS */}
      { (assessment.assessorComments ?? '').length > 0 && <>
      <Grid item xs={12}>
        <Typography variant='h6'>Assessor Comments</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{whiteSpace: 'pre-wrap'}}>{assessment.assessorComments}</Typography>
      </Grid>
      </>}

      {/* TOTAL FINDINGS */}
      <Grid item xs={12}>
        <Typography variant='h6'>Total Findings</Typography>
      </Grid>
      <Grid item xs={12}>
        <TotalFindings assessment={assessment} assessmentItems={props.assessmentItems} />
      </Grid>

      {/* SUMMARY OF FINDINGS */}
      { showSummaryOfFindings() && <>
        <Grid item xs={12}>
          <Typography variant='h6'>Summary of Findings</Typography>
        </Grid>
        <Grid item xs={12}>
            <SummaryOfFindings exportable={true} assessmentId={props.assessment.id} assessmentItems={props.assessmentItems} includedModels={props.includedModels} />
        </Grid>
        </>
      }
        
    </Grid>
  }</>)
}