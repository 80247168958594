import { default as React, useContext, useEffect, useState } from 'react'
import { Card, Box, Grid, TextField, Typography, FormControl, Select, MenuItem, FormHelperText } from '@mui/material'
import { validateFieldChange } from '../../form/Form'
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
import EventBus from '../../utils/EventBus'
import { saveOwnership, searchOwnerships } from '../data'
import { useNavigate } from 'react-router-dom'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import ListManufacturers from '../../manufacturer/ListManufacturers'
import { Stack } from '@mui/system'

export default function OwnershipDetailsSection(props = { ownership: undefined, setOwnership: undefined, editMode: false, setSectionIsEditable: undefined, onStateValidation: undefined }) {
  const navigate = useNavigate()
  const ownership = props.ownership
  const { setOwnership, editMode, setSectionIsEditable, stopLoading, startLoading } = props
  const [valid, setValid] = useState(false)
  const [ownershipDetail, setOwnershipDetail] = useState({ status: 'Active', isProducing: 'Y' })
  const roleDetails = useContext(RoleContext)
  const [conflicts, setConflicts] = useState({ ownershipName: undefined, ownershipId: undefined })

  const isEditable = () => {
    if (roleDetails.hasRole([ROLES.ADMIN, ROLES.MFI_ADMIN, ROLES.QSE_MGR])) {
      return true
    }
    return false
  }

  const checkConflicts = async ( _record ) => {
    setConflicts({ ownershipName: undefined, ownershipId: undefined })
    if ( ! _record ) _record = ownershipDetail
    const search = {
      anyMatch: true,
      ownershipName: _record.ownershipName,
      ownershipId: _record.ownershipId
    }

    const result = await searchOwnerships(search)
    if (!result || !result.status || result.status === 'error') {
      throw new Error(result?.error ?? 'Could not validate due to unknown error')
    } else if (result.status === 'success' && result.results.length) {
      const hasConflict = !_record.uuid || result.results.some((o) => o.uuid !== _record.uuid)
      if (hasConflict) {
        let _conflicts = {}
        result.results.forEach((o) => {
          if ((_record.uuid ?? '') !== o.uuid) {
            ['ownershipName', 'ownershipId'].forEach((f) => {
              if ((_record[f] ?? '').toLowerCase() === o[f].toLowerCase()) {
                _conflicts = { ..._conflicts, [f]: 'exists'}
              }
            })
          }
        })
        setConflicts( { ...conflicts, ..._conflicts})
      }
      return hasConflict
    } 
    return false
  }

  const initializeOwnershipDetail = ( _ownership ) => {
    setOwnershipDetail(structuredClone(_ownership))
  }
  const validateForm = (_record = false) => {
    let _valid = false
    

    if ( !_record ) _record = ownershipDetail

    const validations = [
      _record.ownershipName !== undefined,
      _record.ownershipName !== '',
    ]
    _valid = validations.every(v => {
      return v
    })

    setValid(_valid)
    if (props.onStateValidation !== undefined) {
      props.onStateValidation(_record, _valid)
    }
    return _valid
  }

  const onChange = ((e) => {
      const { id, name, value } = e.target
      validateFieldChange(id ?? name,value, ownershipDetail, setOwnershipDetail, validateForm)
  })

  useEffect(() => {
    if (props.ownership) {
      initializeOwnershipDetail(props.ownership)
      if (setSectionIsEditable !== undefined) setSectionIsEditable(!editMode && isEditable())
    }

  }, [props.ownership, props.editMode])

  const handleSave = async () => {
    console.log('Final validation...')
    try {
      const conflicts = await checkConflicts( ownershipDetail )
      if (conflicts) {
        throw new Error('Conflict')
      } else {

        console.log('Saving...')
        if (valid) {
          const result = await saveOwnership(ownershipDetail.uuid, ownershipDetail)
          if (result.status === 'success') {
            if (result.created) {
              navigate(`/ownership/edit/${result.instance.uuid}/details`)
            } else {
              props.onStateValidation(result.instance, false)
              setValid(false)
              setOwnership(result.instance)
            }
          } else {
            throw new Error(result.error)
          }
        } else {
          throw new Error('Not valid for Save')
        }
      }
    } catch (error) {
      console.error(error)
      // TODO present error to user
    }
  }

  useEffect(() => {
    EventBus.on('saveRequested', handleSave)
    return () => {
      EventBus.removeListener('saveRequested', handleSave)
    }
  }, [ownershipDetail, valid])

  return (
    <>
    <Card sx={{ padding: '12px' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowGap={1} spacing={1}>
          <Grid item xs={12} md={6}>
            <Grid container columnGap={1} rowGap={0} spacing={1}>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Name:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
              { !editMode &&
                  <Typography fontWeight='regular'> {ownershipDetail.ownershipName}</Typography>
                }
                { editMode && 
                  <>
                  <TextField size='small' fullWidth
                  error={ (!ownershipDetail.ownershipName || ownershipDetail.ownershipName.trim() === '')}
                    id="ownershipName"
                    variant='outlined'
                    value={ownershipDetail.ownershipName ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  { editMode && ((conflicts.ownershipName ?? '') !== '') && 
                    <FormHelperText error={true}>Conflict { conflicts.ownershipName }</FormHelperText>
                  }
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Address:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                { !editMode &&
                  <Typography fontWeight='regular'> {ownershipDetail.hqAddress}</Typography>
                }
                { editMode && 
                  <TextField size='small' fullWidth
                      id="hqAddress"
                      variant='outlined'
                      value={ownershipDetail.hqAddress ?? ''}
                      onChange={onChange}
                      disabled={!editMode} />
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>City:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                { !editMode &&
                  <Typography fontWeight='regular'> {ownershipDetail.hqCity}</Typography>
                }
                { editMode && 
                  <TextField size='small' fullWidth
                    id="hqCity"
                    variant='outlined'
                    value={ownershipDetail.hqCity ?? ''}
                    onChange={onChange}
                    disabled={!editMode} />
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>State:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                { !editMode &&
                    <Typography fontWeight='regular'> {ownershipDetail.hqState}</Typography>
                }
                { editMode && 
                  <TextField size='small' fullWidth
                      id="hqState"
                      variant='outlined'
                      value={ownershipDetail.hqState ?? ''}
                      onChange={onChange}
                      disabled={!editMode} />
                }
                </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Zipcode:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                { !editMode &&
                  <Typography fontWeight='regular'> {ownershipDetail.hqZipcode}</Typography>
                }
                { editMode && 
                <TextField size='small' fullWidth
                  id="hqZipcode"
                  variant='outlined'
                  value={ownershipDetail.hqZipcode ?? ''}
                  onChange={onChange}
                  disabled={!editMode} />
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container columnGap={1} rowGap={0} spacing={1}>
            <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Global ID:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                { (!editMode
                    // || (ownership?.ownershipId ?? '') !== ''
                  ) &&
                  <Typography fontWeight='regular'> {ownershipDetail.ownershipId}</Typography>
                }
                { editMode 
                // && (ownership?.ownershipId ?? '') === '' 
                  &&
                  <>
                  <TextField size='small' fullWidth
                    id="ownershipId"
                    variant='outlined'
                    value={ownershipDetail.ownershipId ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                  { editMode && ((conflicts.ownershipId ?? '') !== '') && 
                    <FormHelperText error={true}>Conflict { conflicts.ownershipId }</FormHelperText>
                  }
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>ISSCOM ID:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                { !editMode &&
                  <Typography fontWeight='regular'> {ownershipDetail.isscomId}</Typography>
                }
                { editMode && 
                  <TextField size='small' fullWidth
                    id="isscomId"
                    variant='outlined'
                    value={ownershipDetail.isscomId ?? ''}
                    onChange={onChange}
                    disabled={!editMode}
                  />
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Status:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                { !editMode &&
                  <Typography fontWeight='regular'> {ownershipDetail.status}</Typography>
                }
                { editMode && 
                  <FormControl size='small' disabled={! editMode}>
                  <Select name='status' size='small' sx={{ minWidth: '100px'}}
                    error={(!ownershipDetail.status || ownershipDetail.status === '')}
                    value={ownershipDetail.status ?? ''} 
                    onChange={onChange}
                    >
                      <MenuItem value='Active'>Active</MenuItem>
                      <MenuItem value='Inactive'>Inactive</MenuItem>
                  </Select>
                  </FormControl>
                }
              </Grid>
              <Grid item xs={12} sm={3} alignContent={'center'}>
                <Typography fontWeight='bold' color="text.secondary" sx={{ textAlign: 'right'}}>Producing:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
              { !editMode &&
                  <Typography fontWeight='regular'> {ownershipDetail.isProducing === 'Y' ? 'Yes' : 'No'}</Typography>
                }
                { editMode && 
                  <FormControl size='small' disabled={! editMode}>
                  <Select name='isProducing' size='small' sx={{ minWidth: '75px'}}
                    error={(!ownershipDetail.isProducing || ownershipDetail.isProducing === '')}
                    value={ownershipDetail.isProducing ?? ''} 
                    onChange={onChange}
                    >                  
                      <MenuItem value='Y'>Yes</MenuItem>
                      <MenuItem value='N'>No</MenuItem>
                  </Select>
                  </FormControl>
                }
              </Grid>
              
            </Grid>
          
          </Grid>
        </Grid>        
      </Box>
    </Card>
    { !editMode && ownership.Manufacturers && !!ownership.Manufacturers.length && 
      <Stack rowGap={1} paddingTop={'10px'}>
        <Typography fontWeight='bold' color='text.secondary'>Facilities</Typography>
        <ListManufacturers minimal={true} startLoading={startLoading} stopLoading={stopLoading} searchFilter={{isActive: true, ownershipUuid: ownership.uuid}}/>
      </Stack>
    }
    </>
  )
}