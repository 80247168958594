import { useContext, useState, useEffect, useMemo } from 'react'
import RoleContext from '../auth/RoleContext'
import { getRequestConfig, getToken } from '../auth/auth'
import { ROLES } from '../auth/role'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox, Autocomplete, Grid, Button, InputLabel, Select, TextField, Typography } from '@mui/material'
import FormCard from './FormCard'
import FormElement from './FormElement'
import { Delete } from '@mui/icons-material'

export default function OwnershipSelection(props = {}) {
  const roleDetails = useContext(RoleContext)

  const { 
    setValues,
    startingValues,
    isFacilityBottler,
    isCorpBottler,
    disabled,
  } = props

  const [selectionType, setSelectionType] = useState('ownership')

  const [ownershipList, setOwnershipList] = useState([])
  const [ownershipAcList, setOwnershipAcList] = useState([])
  const [manufacturerList, setManufacturerList] = useState([])
  const [manufacturerAcList, setManufacturerAcList] = useState([])
  const [distributionCenterList, setDistributionCenterList] = useState([])
  const [distributionCenterAcList, setDistributionCenterAcList] = useState([])
  const [productionCodes, setProductionCodes] = useState({})
  const [otherOwnership, setOtherOwnership] = useState(null)

  const [ownershipId, setOwnershipId] = useState(null)
  const [manufacturerId, setManufacturerId] = useState(null)
  const [distributionCenterId, setDistributionCenterId] = useState(null)
  const [supplierName, setSupplierName] = useState(null)
  const [producedHere, setProducedHere] = useState(true)
  const [producedHereIsDefault, setProducedHereIsDefault] = useState(true)

  const [loadingManufacturers, setLoadingManufacturers] = useState(false)
  const [loadingDistributionCenters, setLoadingDistributionCenters] = useState(false)
  const [loadingOwnerships, setLoadingOwnerships] = useState(false)

  const extraOwnershipOptions = isFacilityBottler || isCorpBottler ? [] : [
    { ownershipId: -100, ownershipName: 'Supplier' },
    { ownershipId: -101, ownershipName: 'Distribution Center' },
  ]

  const notNullOrUndefined = (v) => v !== null && v !== undefined

  useEffect(() => {
    if (notNullOrUndefined(startingValues?.otherOwnership)) {
      setSelectionType('other')
      setOtherOwnership(startingValues?.otherOwnership)
    }
    if (notNullOrUndefined(startingValues?.ownershipId)) setOwnershipId(startingValues?.ownershipId)
    if (notNullOrUndefined(startingValues?.manufacturerId)) setManufacturerId(startingValues?.manufacturerId)
    if (notNullOrUndefined(startingValues?.distributionCenterId)) setDistributionCenterId(startingValues?.distributionCenterId)
    if (notNullOrUndefined(startingValues?.supplierName)) setSupplierName(startingValues?.supplierName)
    if (producedHereIsDefault && (startingValues?.producedHere === true || startingValues?.producedHere === false)) {
      setProducedHereIsDefault(false)
      setProducedHere(startingValues?.producedHere || false)
    }
  }, [
    startingValues,
    producedHereIsDefault
  ]);

  const ownership = useMemo(
    () => {
      if(!ownershipId) return null
      return ownershipAcList.find((v) => v.ownershipId && `${v.ownershipId}` === `${ownershipId}`) || null
    },
    [ownershipAcList, ownershipId]
  );

  const manufacturer = useMemo(
    () => {
      if(!manufacturerId) return null
      return manufacturerAcList.find((v) => v.manufacturerId && `${v.manufacturerId}` === `${manufacturerId}`) || null
    },
    [manufacturerAcList, manufacturerId]
  );

  const distributionCenter = useMemo(
    () => distributionCenterAcList.find((v) => `${v.id}` === `${distributionCenterId}`) || null,
    [distributionCenterAcList, distributionCenterId]
  );

  const supplierSelected = `${ownershipId}` === '-100'
  const dcSelected = `${ownershipId}` === '-101'
  const ownershipNotSelected = supplierSelected || dcSelected
  const ownershipSelected = (ownershipId ? true : false) && !supplierSelected && !dcSelected
  const facilitySelected = manufacturerId ? true : false
  const anyOwnershipSelected = ownershipSelected || supplierSelected || dcSelected

  useEffect(() => {
    setValues({
      manufacturerId,
      ownershipId,
      distributionCenterId,
      supplierName: supplierName,
      producedHere: producedHere,
      otherOwnership,
      selectionType
    })
  }, [
    otherOwnership,
    manufacturerId,
    ownershipId,
    distributionCenterId,
    supplierName,
    producedHere,
    selectionType
  ])

  const {
    isAnyone,
    gridItemProps,
    fwGridItemProps,
    loadingExistingImLog,
  } = props

  const getProductionCode = () => {
    const code = productionCodes[manufacturer?.manufacturerId] || ""
    return code
  }

  const clearSelections = () => {
    setOwnershipId(null)
    setDistributionCenterId(null)
    setSupplierName(null)
    setManufacturerId(null)
    setProducedHere(false)
    setOtherOwnership(null)
  }

  const loadDcOptions = async () => {
    const token = await getToken()

    const basePath = `${ApiGateway.imLogs}/distribution-centers/search`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const search = {}
    if (roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      search['byMyKoId'] = true
    }
    const response = await axios.post(basePath, search, options)
    if (response.data) {
      const { results } = response.data
      setDistributionCenterList(results)
      setDistributionCenterAcList(results)
    }
  }

  const loadOwnershipOptions = async () => {
    if(selectionType === 'other') return

    const token = await getToken()

    const basePath = `${ApiGateway.ownership}/search`
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const search = {
      isActive: true
    }
    if (roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      search['byMyKoId'] = true
    }
    const response = await axios.post(basePath, search, options)
    if (response.data) {
      const { results } = response.data
      setOwnershipList(results)
      if(isFacilityBottler) {
        setOwnershipAcList([...results])
      } else {
        setOwnershipAcList([...extraOwnershipOptions, ...results])
      }
    }
  }

  const loadManufacturers = async (_ownershipId) => {
    if(selectionType === 'other') return
    if (ownershipSelected) {
      const token = await getToken()

      const basePath = `${ApiGateway.manufacturer}/search`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.idToken}`
        }
      }
      const search = {
        ownershipId: _ownershipId,
        isActive: true
      }
      if (roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
        search['byMyKoId'] = true
      }
      const response = await axios.post(basePath, search, options)
      if (response.data) {
        const { results } = response.data
        setManufacturerList(results)
        setManufacturerAcList(results)
        if(manufacturerAcList.length === 1) {
          setManufacturerId(manufacturerAcList[0]?.manufacturerId)
        }

        const newProductionCodes = results.reduce((s, man) => {
          s[man?.manufacturerId] = man?.productionCode
          return s
        }, {})
        setProductionCodes(newProductionCodes)
      }
    }
  }
  
  useEffect(() => {
    const extraOwnershipIds = extraOwnershipOptions.map(x => x.ownershipId)
    const filtered = ownershipAcList.filter(f => !extraOwnershipIds.includes(f.ownershipId))
    if(filtered.length === 1) {
      setOwnershipId(filtered[0]?.ownershipId)
    }
  }, [ownershipAcList, ownership])

  useEffect(() => {
    if(manufacturerAcList.length === 1) {
      setManufacturerId(manufacturerAcList[0]?.manufacturerId)
    }
  }, [manufacturerAcList])

  useEffect(() => {
    (async () => {
      setLoadingOwnerships(true)
      await loadOwnershipOptions()
      setLoadingOwnerships(false)
    })()
  }, [selectionType])

  useEffect(() => {
    (async () => {
      if (dcSelected) setLoadingDistributionCenters(true)
      setLoadingManufacturers(true)

      const promises = []
      if(ownership?.ownershipId) promises.push(loadManufacturers(ownership?.ownershipId))
      
        if (dcSelected) promises.push(loadDcOptions())
      await Promise.all(promises)

      if (dcSelected) setLoadingDistributionCenters(false)
      setLoadingManufacturers(false)
    })()
  }, [ownership, dcSelected])

  return (
    <Grid {...fwGridItemProps} item>
      <FormCard
        title={`${props.title}`}
        defaultExpanded={props.defaultExpanded}
        showProgress={false}
        summaryStyle={{ border: '.5px solid gray', borderBottomWidth: '0' }}
        detailsStyle={{ border: '.5px solid gray', borderTopWidth: '0' }}
      >
        <FormElement show={!isFacilityBottler} gridItemProps={fwGridItemProps} loading={loadingExistingImLog}>
          <ToggleButtonGroup
            disabled={props.disabled}
            color="primary"
            value={selectionType}
            exclusive
            onChange={(e) => {
              clearSelections()
              setSelectionType(e.target.value)
            }}
            aria-label="Ownership Selection"
          >
            <ToggleButton value="ownership">Select Ownership</ToggleButton>
            <ToggleButton value="other">Other</ToggleButton>
          </ToggleButtonGroup>
        </FormElement>

        {selectionType === 'ownership' && <>

          <FormElement show={isAnyone} size='small' gridItemProps={gridItemProps} loading={loadingExistingImLog || loadingOwnerships}>
            <Autocomplete
              id="ownership"
              disabled={props.disabled}
              size='small'
              getOptionLabel={(option) => option?.ownershipName || ""}
              filterOptions={(x) => x}
              options={ownershipAcList}
              autoComplete
              filterSelectedOptions
              value={ownership}
              noOptionsText="No results"
              // disabled={isCorpBottler || isFacilityBottler}
              onChange={(event, newValue) => {
                setOwnershipId(newValue?.ownershipId)
              }}
              onInputChange={(event, newInputValue) => {
                if (!newInputValue) {
                  clearSelections()
                  setOwnershipAcList([...extraOwnershipOptions, ...ownershipList])
                } else {
                  const newOwnershipList = ownershipList.filter(_own => {
                    const lowerInput = (newInputValue || "").toLowerCase();
                    const lowerItem = (_own?.ownershipName || "").toLowerCase();
                    return lowerItem.includes(lowerInput);
                  })
                  setOwnershipAcList([...extraOwnershipOptions, ...newOwnershipList])
                }
              }}
              renderInput={(params) => (
                <TextField {...params} value={params.value || ''} label="Ownership" fullWidth />
              )}
              renderOption={(props, option, i) => {
                const isLast = i?.index + 1 === ownershipAcList.length
                const isDc = option?.ownershipId === -101
                const isSupplier = option?.ownershipId === -100
                return (
                  <li {...props} >
                    <Grid paddingBottom={isDc && !isLast ? '10px' : ''} borderBottom={isDc && !isLast ? 'solid 1px gray' : ''} container alignItems="center">
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        <Typography fontWeight={isDc || isSupplier ? 'bold' : 'regular'} variant="body2" color="text.secondary">
                          {option?.ownershipName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </FormElement>

          {ownershipSelected && <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog || loadingManufacturers}>
            <Autocomplete
              id="manufacturer"
              disabled={isFacilityBottler || props.disabled}
              size='small'
              getOptionLabel={(option) => `${option?.manufacturerName}` || ""}
              filterOptions={(x) => x}
              options={manufacturerAcList}
              autoComplete
              filterSelectedOptions
              value={manufacturer}
              noOptionsText="No results"
              onChange={(event, newValue) => setManufacturerId(newValue?.manufacturerId)}
              onInputChange={(event, newInputValue) => {
                setProducedHere(true)
                if (!newInputValue) {
                  setManufacturerAcList(manufacturerList)
                } else {
                  const newMfrList = manufacturerList.filter(mfr => {
                    const lowerInput = (newInputValue || "").toLowerCase();
                    const lowerItem = (mfr.manufacturerName || "").toLowerCase();
                    return lowerItem.includes(lowerInput);
                  })
                  setManufacturerAcList(newMfrList)
                }
              }}
              renderInput={(params) => (
                <TextField {...params} value={params.value || ''} label="Facility" fullWidth />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        <Typography variant="body2" color="text.secondary">
                          {option?.manufacturerName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </FormElement>}

          {supplierSelected && <FormElement show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog}>
            <TextField
              id="supplierName"
              disabled={props.disabled}
              size='small'
              value={supplierName || ''}
              onChange={(e) => setSupplierName(e.target.value)}
              label="Supplier Name"
              fullWidth
            />
          </FormElement>}

          {dcSelected && 
          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog || loadingDistributionCenters}>
            <Autocomplete
              id="distributionCenter"
              disabled={props.disabled}
              size='small'
              getOptionLabel={(option) => `${option?.name}` || ""}
              filterOptions={(x) => x}
              options={distributionCenterAcList}
              autoComplete
              filterSelectedOptions
              value={distributionCenter}
              noOptionsText="No results"
              onChange={(event, newValue) => setDistributionCenterId(newValue?.id)}
              onInputChange={(event, newInputValue) => {
                if (!newInputValue) {
                  setDistributionCenterAcList(distributionCenterList)
                } else {
                  const newList = distributionCenterList.filter(x => {
                    const lowerInput = (newInputValue || "").toLowerCase();
                    const lowerItem = (x.name || "").toLowerCase();
                    return lowerItem.includes(lowerInput);
                  })
                  setDistributionCenterAcList(newList)
                }
              }}
              renderInput={(params) => (
                <TextField {...params} value={params.value || ''} label="Distribution Center" fullWidth />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        <Typography variant="body2" color="text.secondary">
                          {option?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </FormElement>}

          {facilitySelected && <FormElement show={isAnyone} gridItemProps={{ item: true, xs: 2 }} loading={loadingExistingImLog}>
            <TextField
              id="facilityCodeId"
              size='small'
              value={getProductionCode() || ''}
              label="Facility Code"
              disabled
              fullWidth
            />
          </FormElement>}

          {anyOwnershipSelected && <FormElement show={isAnyone} gridItemProps={{ item: true, xs: 2 }} loading={loadingExistingImLog}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={props.disabled}
                    checked={producedHere}
                    onChange={(e) => {
                      setProducedHere(e.target.checked)
                    }}
                  />
                }
                label="Produced Here" />
            </FormGroup>
          </FormElement>}
        </>}

        <FormElement show={selectionType === 'other'} gridItemProps={fwGridItemProps} loading={loadingExistingImLog}>
          <TextField
            id="otherOwnership"
            disabled={props.disabled}
            size='small'
            value={otherOwnership || ''}
            onChange={(event, newValue) => {
              setOtherOwnership(event.target.value)
            }}
            label="Describe Affected Ownership"
            fullWidth
          />
        </FormElement>

        <Grid item xs={12} style={{ marginTop: '4px' }}>
          {!isFacilityBottler && !props.disabled && <Button
            style={{
              position: 'absolute',
              right: '14px',
              bottom: '4px'
            }}
            onClick={props.remove}
            size="small">
            Remove Ownership
            <Delete size="small" />
          </Button>}
        </Grid>

      </FormCard>

    </Grid>

  )
}