import { useContext, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
import { ASMT_STATUS } from '../../utils/constants'
import SummaryOfFindings from './SummaryOfFindings'
import SummaryOutcome from './SummaryOutcome'
import TotalFindings from './TotalFindings'

function TabPanel (props) {
  const { children, selectedTab, thisTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== thisTab}
      id={`tabpanel-${thisTab}`}
      aria-labelledby={`tab-${thisTab}`}
      {...other}
    >
      {selectedTab === thisTab && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  thisTab: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default function TabbedAssessmentSummary(props={includedModels:[], assessmentItems: [], assessment: undefined}) {
  const roleDetails = useContext(RoleContext)
  const [selectedTab, setSelectedTab] = useState(0)
  const assessment = props.assessment

  const showSummaryOfFindings = () => {
    return roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) || (assessment 
      && [ASMT_STATUS.COMPLETED, ASMT_STATUS.FINALIZED].includes(assessment.status))
  }

  const tabChange = (event, toTab) => {
    setSelectedTab(toTab)
  }

  return ( <>{ assessment && 
    <Grid container>
      <Grid item xs={12} md={12}>
        <Tabs value={selectedTab} onChange={tabChange}>
          <Tab label='Outcome' id={`tab-0`}></Tab>
          <Tab label='Assessor Comments' id={`tab-1`}></Tab>
          <Tab label='Total Findings' id={`tab-2`}></Tab>
          
          {
            showSummaryOfFindings() && <Tab label='Summary of Findings' id={`tab-3`}></Tab>
          }
        </Tabs>

        {/* OUTCOME */}
        <TabPanel selectedTab={selectedTab} thisTab={0}>
          <SummaryOutcome assessment={assessment} assessmentItems={props.assessmentItems} includedModels={props.includedModels} />
        </TabPanel>

        {/* ASSESSOR COMMENTS */}
        <TabPanel selectedTab={selectedTab} thisTab={1}>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{whiteSpace: 'pre-wrap'}}>{assessment.assessorComments}</Typography>
            </Grid>
          </Grid>
        </TabPanel>

        {/* TOTAL FINDINGS */}
        <TabPanel selectedTab={selectedTab} thisTab={2}>
          <TotalFindings assessment={assessment} assessmentItems={props.assessmentItems} />
          
        </TabPanel>

        {/* SUMMARY OF FINDINGS */}
        { showSummaryOfFindings() &&
        <TabPanel selectedTab={selectedTab} thisTab={3}>
          {/* { assessmentFindings.length > 0 &&  */}
              <SummaryOfFindings exportable={true} assessmentId={props.assessment.id} assessmentItems={props.assessmentItems} includedModels={props.includedModels} />
          {/* } */}
        </TabPanel>
        }
      </Grid>
    </Grid>
  }</>)
}