import React from 'react';

function percent(n) {
  return Math.round(n * 1000) / 10;
}

export default class StandardDotLabel extends React.Component {

  render () {
    const {x, y, stroke, value, isPercentage} = this.props;
   	return <text x={x} y={y} dy={-10} fill={stroke} fontSize={12} textAnchor="middle">{ isPercentage ? percent(value / 100) + '%': value }</text>
  }
};
