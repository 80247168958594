import {CartesianGrid, LineChart, Line, XAxis, YAxis, ReferenceArea, ReferenceLine, ResponsiveContainer} from 'recharts';
import { ApiGateway } from '../config/config.js';
import { Skeleton, Button, Grid, MenuItem, Select, Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning';
import { Link } from 'react-router-dom';
import { default as bus } from '../utils/EventBus'
import Event from '../utils/EventNames.js';
import DecimalFormat from '@sei-atl/decimal-format';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getRequestConfig } from '../auth/auth'
import ScoreDot from '../charts/Dots/ScoreDot';
import StandardDotLabel from '../charts/Labels/StandardDotLabel';
import axios from 'axios';
import dayjs from 'dayjs'

const Chart = {
  animationDuration: 750,
  interpolation: 'linear',
  groupStrokes: ['#00a65a', '#00c0ef', '#f39c12' ,'#dd4b39'],
  groupFills: ['#00a65a', '#00c0ef', '#f39c12' ,'#dd4b39'],
  stroke: '#999999',
  strokes: ['#00a65a', '#003947', '#00c0ef', '#f39c12', '#dd4b39', '#663399', '#333333' ],
  fill: 'transparent',
  fills: ['#00a65a', '#003947', '#00c0ef', '#f39c12', '#dd4b39', '#663399', '#333333' ]
};

function percent(n) {
  return Math.round(n * 1000) / 10;
}

const axis = {
  domain: (min,max,round=false) => {
    var factor = 0.0;
    var range = max-min;
    if(range == 0) {
      newMax = max + 1;
      newMin = min - 1;
    }
    else {
      do {
        factor += 0.1;
        var newMin = round ? Math.floor(Math.max(0,(min - (range*factor)))) : Math.max(0,(min - (range*factor)));
        var newMax = round ? Math.ceil(max + (range*factor)) : max + (range*factor);
        if (factor > 2) {
          break;
        }
      } while (
        (newMin == min || newMin == 0) &&
        (newMax == max || newMax == 0)
      );
    }
    return {
      "max": round ? Math.ceil(newMax) : newMax,
      "min": round ? Math.floor(newMin) : newMin < 0.0001 ? 0 : newMin
    };
  },
  toPercent: (t) => {
      return t + "%";
  }
}

export default function IndexChangesOverTimeChart(props) {
  const [data, setData] = useState([])
  const [domains, setDomains] = useState({})
  const [indexChartDataKey, setIndexChartDataKey] = useState('totalScore')
  const [indexScoreFrom, setIndexScoreFrom] = useState(dayjs().subtract(1, 'year').format('YYYY-MM-DD'))
  const [snapshot, setSnapshot] = useState({})
  const [activeButton, setActiveButton] = useState(1)
  const [dataRange, setDataRange] = useState([0, 100])
  
  const navigate = useNavigate()
  
  let chartDots = [];
  const dataKeyMap = {
    totalScore: 'Score',
    commitment: 'Commitment',
    compliance: 'Compliance',
    capability: 'Capability',
    grouping: 'Group'
  };


  const id = props?.mfi?.id
  useEffect(() => {
    const refreshData = async (indexScoreFrom, id) => {
      const requestConfig = await getRequestConfig()
      if(indexScoreFrom && id) {
        let url = `${ApiGateway.fitnessIndexes}/${id}/scores/from/${indexScoreFrom}`;
        try {
          let response = await axios.get(url, requestConfig)
          let resp = response.data
  
          resp = (resp && resp.length > 0) ? resp : [];
          let dataMaxes = {};
          let dataMins = {};
          let domains = {};
          if(resp.length > 0) {
            let keys = Object.keys(resp[0]);
            keys.reduce((s,item) => {
              dataMaxes[item] = 0;
              dataMins[item] = 100;
            },{});
            let df = new DecimalFormat('#.#');
            resp = resp.map((item) => {
              for (let i=0;i<keys.length;i++) {
                dataMaxes[keys[i]] = Math.max(dataMaxes[keys[i]], item[keys[i]]);
                dataMins[keys[i]] = Math.min(dataMins[keys[i]], item[keys[i]]);
                if([
                  'commitment',
                  'compliance',
                  'capability',
                  'totalScore'
                  ].indexOf(keys[i]) > -1) {
                    item[keys[i]] = df.format(item[keys[i]]);
                }
              }
              return item;
            });
            keys.map((key) => {
              domains[key] = axis.domain(Math.max(0, dataMins[key] * .98), Math.min(dataMaxes[key] * 1.02, 100), true);
            });
          }
          setData(resp)
          setDomains(domains)
          setDataRange([Math.max(0, dataMins[indexChartDataKey]), Math.min(dataMaxes[indexChartDataKey], 100)])
        } catch(error) {
          console.log('ERROR: ', error)
          bus.emit(Event.ALERT, {
            message: `Issues Found... Could not retrieve the scores! ${error}`,
            style: 'danger'
          })
        }
      }
    }
    refreshData(indexScoreFrom, id)
  }, [indexScoreFrom, id])

  const setIndexScoreYearsBackFrom = (years) => {
    let from = dayjs().subtract(years, 'year').format('YYYY-MM-DD')
    setIndexScoreFrom(from);
  }

  const formatFacilityRisk = (value) => {
    return ['', 'Low', 'Medium', 'High'][value];
  }

  const getLabelClassName = (dataKey, titleDataKey) => {
    return (dataKey === titleDataKey) ? 'title-label' : 'label';
  }

  const getValueClassName = (dataKey, titleDataKey) => {
    return 'body-color'
    return (dataKey === titleDataKey) ? 'title-value' : 'value';
  }

  const onIndexChartDataKeyChange = (event) => {
    const target = event.target;
    const value = target.value;
    setIndexChartDataKey(value)
  }
    
  const {createdOn, totalScore, commitment, facilityRisk, compliance, grouping, capability } = snapshot ? snapshot : {};

  let greenRefs = [
    <ReferenceLine y={Math.max(dataRange?.[0], 90)} stroke="#00a65a" strokeOpacity={0.3} />,
    <ReferenceArea y1={Math.max(dataRange?.[0], 90)} y2={Math.min(dataRange?.[1], 100)} fill="#00a65a" fillOpacity={0.1} />,
    <ReferenceArea y1={Math.min(dataRange?.[1], 100)} y2={Math.max(dataRange?.[0], 90)} fill="#00a65a" fillOpacity={0.1} />
  ]

  let blueRefs = [
    <ReferenceLine y={Math.max(dataRange?.[0], 80)} stroke="#00c0ef" strokeOpacity={0.3} />,
    <ReferenceArea y1={Math.max(dataRange?.[0], 80)} y2={Math.min(dataRange?.[1], 89.9)} fill="#00c0ef" fillOpacity={0.1} />,
    <ReferenceArea y1={Math.min(dataRange?.[1], 89.9)} y2={Math.max(dataRange?.[0], 80)} fill="#00c0ef" fillOpacity={0.1} />
  ]

  let yellowRefs = [
    <ReferenceLine y={Math.max(dataRange?.[0], 70)} stroke="#f39c12" strokeOpacity={0.3} />,
    <ReferenceArea y1={Math.max(dataRange?.[0], 70)} y2={Math.min(dataRange?.[1], 79.9)} fill="#f39c12" fillOpacity={0.1} />,
    <ReferenceArea y1={Math.min(dataRange?.[1], 79.9)} y2={Math.max(dataRange?.[0], 70)} fill="#f39c12" fillOpacity={0.1} />
  ]

  let redRefs = [
    <ReferenceArea y1={dataRange?.[0]} y2={Math.min(dataRange?.[1], 69.9)} fill="#dd4b39" fillOpacity={0.1} />,
    <ReferenceArea y1={Math.min(dataRange?.[1], 69.9)} y2={dataRange?.[0]} fill="#dd4b39" fillOpacity={0.1} />
  ]

  let referenceAreas = [...greenRefs, ...blueRefs, ...yellowRefs, ...redRefs]
  
  const ScoreDetailRow = ({isPercent, label, id, value}) => {
    return <Grid item xs={10}>
      <Grid container>
        <Grid className='contents-right' item md={6}>
          <Typography fontWeight={600}>{label}:</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography className={getValueClassName(id, indexChartDataKey)}>{isPercent ? `${percent(value/100)}%` : value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  }

  return (
    props.loading ? <Skeleton></Skeleton> : <div>
      {(data === undefined || data.length === 0) &&
        <div className="p1 center"><WarningIcon /> Trend data not available</div>
      }
      {data?.length ?
        <>
          <Select
              size='small'
              id="indexChartDataKey"
              value={indexChartDataKey}
              sx={{ marginRight: '3em' }}
              onChange={onIndexChartDataKeyChange}
            >
            <MenuItem value="totalScore">Score</MenuItem>
            <MenuItem value="commitment">Commitment</MenuItem>
            <MenuItem value="compliance">Compliance</MenuItem>
            <MenuItem value="capability">Capability</MenuItem>
            <MenuItem value="facilityRisk">Facility Risk</MenuItem>
            <MenuItem value="grouping">Group</MenuItem>
          </Select>
          <Button variant={activeButton === 1 ? 'contained' : 'outlined' } sx={{margin: '0 .25em'}} onClick={() => { setActiveButton(1); setIndexScoreYearsBackFrom(1); }}>1 Yr</Button>
          <Button variant={activeButton === 2 ? 'contained' : 'outlined' } sx={{margin: '0 .25em'}} onClick={() => { setActiveButton(2); setIndexScoreYearsBackFrom(2); }}>2 Yrs</Button>
          <Button variant={activeButton === 5 ? 'contained' : 'outlined' } sx={{margin: '0 .25em'}} onClick={() => { setActiveButton(5); setIndexScoreYearsBackFrom(5); }}>5 Yrs</Button>
          <Button variant={activeButton === 1000 ? 'contained' : 'outlined' } sx={{margin: '0 .25em'}} onClick={() => { setActiveButton(1000); setIndexScoreYearsBackFrom(1000); }}>Max</Button>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data} margin={{top: 20, right: 50, left: 20, bottom: 5}}>
              <XAxis dataKey="createdOn" name="Date" />
              <YAxis domain={[domains[indexChartDataKey].min, domains[indexChartDataKey].max]} dataKey={indexChartDataKey} name={dataKeyMap[indexChartDataKey] || 'Score'} />
              {referenceAreas}
              <CartesianGrid strokeDasharray="3 3"/>

              <Line
                animationDuration={Chart.animationDuration}
                type={Chart.interpolation}
                dataKey={indexChartDataKey}
                name="Score"
                stroke={Chart.stroke}
                dot={<ScoreDot onClick={(snapshot, index) => {
                    setSnapshot(snapshot);
                    chartDots.forEach((dot, idx) => {
                      if(idx === index) {
                        dot.select();
                      } else {
                        dot.deselect();
                      }
                    })
                  }} ref={(dot) => {
                    if(dot) {
                      chartDots[dot.props.index] = dot;
                    }
                  }}
                />}
                label={<StandardDotLabel isPercentage={
                  indexChartDataKey === 'totalScore' ||
                  indexChartDataKey === 'commitment' ||
                  indexChartDataKey === 'compliance' ||
                  indexChartDataKey === 'capability'
                }/>}
              />
            </LineChart>

          </ResponsiveContainer>
          
          <Grid md={12} className="chart-tooltip-fixed">
          {snapshot.snapshotDate &&
            <Grid container>
              <Grid item xs={5}>
                {snapshot.snapshotId && <Grid container>
                  <Grid item xs={12}>
                    <ScoreDetailRow label="Snapshot date" value={(snapshot.snapshotDate || '').split('T')?.[0]} />
                  </Grid>
                  <Grid item xs={12}>
                    <Button onClick={e => navigate(`/fitness-indexes/${props.mfi.id}/snapshots/${snapshot.snapshotId}`)} bsStyle="primary">View more details</Button>
                  </Grid>
                </Grid>}
                { (!snapshot.snapshotId) &&
                  <Typography style={{whiteSpace: 'pre-wrap'}}>This data point represents the current month's data.</Typography>
                }
              </Grid>

              <Grid item xs={1}>&nbsp;</Grid>
              <Grid item xs={3}>
                <ScoreDetailRow label="Score" isPercent value={totalScore} id='totalScore' />
                <ScoreDetailRow label="Commitment" isPercent value={commitment} id='commitment' />
                <ScoreDetailRow label="Compliance" isPercent value={compliance} id='compliance' />
              </Grid>
              <Grid item xs={3}>
                <ScoreDetailRow label="Capability" isPercent value={capability} id='capability' />
                <ScoreDetailRow label="Capability" isPercent value={facilityRisk} id='facilityRisk' />
                <ScoreDetailRow label="Group" value={formatFacilityRisk(facilityRisk)} id='grouping' />
              </Grid>
              
            </Grid>
            }
            {!snapshot.snapshotDate &&
              <Typography>Click a point in the graph above to load snapshot details</Typography>
            }
          </Grid>
        </> : <></>
      }
    </div>
  );
}
