function timeAgo(timestamp) {
  const now = new Date();
  const modifiedTime = new Date(timestamp);
  const secondsAgo = Math.floor((now - modifiedTime) / 1000);

  const units = [
    { name: 'year', seconds: 31536000 },
    { name: 'month', seconds: 2592000 },
    { name: 'week', seconds: 604800 },
    { name: 'day', seconds: 86400 },
    { name: 'hour', seconds: 3600 },
    { name: 'minute', seconds: 60 },
    // { name: 'second', seconds: 1 },
  ];

  for (const unit of units) {
    const quotient = Math.floor(secondsAgo / unit.seconds);
    if (quotient >= 1) {
      return `${quotient} ${unit.name}${quotient > 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
}

const dateIsValid = (dateString) => {
  if(dateString === null || dateString === undefined) return false
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

module.exports = {
  timeAgo,
  dateIsValid
}
