import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils.js'
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table'
import { Box, Grid, Paper, IconButton, Button, Typography} from '@mui/material'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';

export default function ListFitnessIndexes(props = {searchFilter: undefined, rowsPerPage: 15, pageIndex: 0 }) {
  const isMounted = useIsMounted()

  const [dataRows, setDataRows] = useState([])

  const listColumns = useMemo(() => [
    {
      accessorKey: 'manufacturerId',
      header: 'ID',
      size: 80,
      editable: false,
      filterVariant: 'text',
      grow: false,
      Cell: ({row}) => {
        const id = row.original.id
        return (
          <Link to={ `/fitness-indexes/${id}` }>
            {row.original.manufacturerId}
          </Link>
        )
      }
    },
    {
      accessorKey: 'name',
      header: 'Plant',
      editable: false,
      filterVariant: 'text',
      grow: true
    },
    {
      accessorKey: 'ownershipName',
      header: 'Ownership',
      size: 110,
      grow: true,
      editable: false,
      filterVariant: 'text',
    },
    {
      accessorKey: 'rtm',
      header: 'Manager',
      editable: false,
      grow: true,
      maxSize: 110,
      filterVariant: 'text',
      Cell: ({row}) => (<Typography sx={{textTransform: 'capitalize'}}>{(row.original.rtm ?? '').toLowerCase()}</Typography>)
    },
    {
      accessorKey: 'totalScore',
      header: 'Score',
      size: 110,
      grow: false,
      editable: false,
      filterVariant: 'number',
      Cell: ({row}) => {
        const s = row.original.totalScore ?? 0
        return Number(s).toFixed(2)
      }
    },
    {
      accessorKey: 'modifiedOn',
      header: 'Updated On',
      size: 150,
      grow: false,
      editable: false,
      filterVariant: 'date',
      Cell: ({row}) => {
        const d = row.original.modifiedOn ? new Date(row.original.modifiedOn) : undefined
        return d ? d.toISOString().split('T')[0] : ''
      }
    },
  ])

  const getFitnessIndexList = async() => {
    if (props.startLoading) props.startLoading()

    const requestConfig = await getRequestConfig()
    let basePath, response, data
    if (props.searchFilter) {
      basePath = `${ApiGateway.fitnessIndexes}/search`
      const search = props.searchFilter
      response = await axios.post(basePath, search, requestConfig)
      data = [response.data.result.data]
    } else {
      basePath = `${ApiGateway.fitnessIndexes}`
      response = await axios.get(basePath, requestConfig)
      data = response.data.data
    }
    
    setDataRows(data)
    
      
    if (props.stopLoading) props.stopLoading()
  }

  

  useEffect(() => {
    if (isMounted()) {
      getFitnessIndexList()
    }
  }, [])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      pagination: {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.rowsPerPage ?? 15
      }
    },
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: false,
  });


  return (
    <div className='App'>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>
        <Grid container rowSpacing={2} >
          <Grid item xs={true} md={true}>            
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )

}