import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend)

export default function ChartJSBarChart (props={
  title: undefined, labelsArray: [], dataArray: [], barColor: undefined,
  xScaleSuggestedMin: undefined, xScaleSuggestedMax: undefined,
  yScaleSuggestedMin: undefined, yScaleSuggestedMax: undefined
}) {

  const options = {
    maintainAspectRatio: true,
    indexAxis: 'y',
    padding: 5,
    elements: {
      bar: {
        borderWidth: 1,
        backgroundColor: props.barColor
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: !!props.title,
        text: props.title
      }
    },
    scales: {
      x: {},
      y: {}
    }
  }
  if (props.xScaleSuggestedMin) options.scales.x.suggestedMax = props.xScaleSuggestedMin
  if (props.xScaleSuggestedMax) options.scales.x.suggestedMax = props.xScaleSuggestedMax
  if (props.yScaleSuggestedMin) options.scales.y.suggestedMax = props.yScaleSuggestedMin
  if (props.yScaleSuggestedMax) options.scales.y.suggestedMax = props.yScaleSuggestedMax

  const labels=props.labelsArray

  const data = {
    labels,
    datasets: [
      {
        data: props.dataArray,
        maxBarThickness: 20
      }
    ]
  }

  return (
    // <div class="barchart-container" style={{position: 'relative', margin: 'auto', height: '70vh', width: '90vh'}}>
      <Bar options={options} data={data} />
    // </div>
  );
}