import axios from "axios"
import { getRequestConfig } from "../../auth/auth"
import { ApiGateway } from "../../config/config"
import { useEffect, useState } from "react"
import { Button, Card, CardContent, CardHeader, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import { Stack } from "@mui/system"

function MiniStatBox(props) {

  const getValue = () => {
    return props.value.replace ? props.value.replace(/%/g, '') : props.value;
  }

  const isPercentage = () => {
    return props.value.indexOf ? (props.value.indexOf('%') > -1) : false;
  }

  const getClassName = () => {
    var className = 'mini-stat-box ';
    if(props.theme) {
      className += props.theme;
    } else {
      if(isPercentage()) {
        const value = getValue();
        if(value > 90) {
          className += 'bg-green';
        } else if (value >= 80) {
          className += 'bg-aqua';
        } else if (value >= 70) {
          className += 'bg-yellow';
        } else {
          className += 'bg-red';
        }
      } else {
        className += 'bg-gray';
      }
    }

    return className;
  }

  let percent = isPercentage() ? <sup>%</sup> : '';

  return (
    <div className={getClassName()}>
      {/* <div className="reflection"></div> */}

      {props.children ? props.children : <h3>{getValue()}{percent}</h3>}
    </div>
  )
}

export default function FitnessIndexCard(props = { manufacturer: undefined, fitnessIndexId: undefined}) {
  const [fitnessIndex, setFitnessIndex] = useState(null)
  const [noIndex, setNoIndex] = useState(null)
  const [creatingIndex, setCreatingIndex] = useState(null)
  const navigate = useNavigate()

  const getFitnessIndex = async() => {
    
    const requestConfig = await getRequestConfig()
    let basePath, response, data
    let search
    if (props.fitnessIndexId !== undefined) {
      search = { id: props.fitnessIndexId } 
    } else {
      search = { manufacturerId: props.manufacturer.manufacturerId }
    }

    basePath = `${ApiGateway.fitnessIndexes}/search`
    response = await axios.post(basePath, search, requestConfig)
    const { result } = response.data
    
    setFitnessIndex(result)
    if (result === null) {
      setNoIndex(true)
    }
  }

  const createFitnessIndex = async() => {
    setCreatingIndex(true)
    const requestConfig = await getRequestConfig()
    const basePath = `${ApiGateway.fitnessIndexes}/create`
    const body = { manufacturerId: props.manufacturer.manufacturerId }

    try {
      const response = await axios.post(basePath, body, requestConfig)
      const { result } = response.data
      
      if (result?.data?.insertId) {
        navigate(`/fitness-indexes/${result.data.insertId}`)
      }
    } catch (error) {

    } finally {
      setCreatingIndex(false)
    }
  }

  useEffect(() => {
    getFitnessIndex()
  }, [props.manufacturer, props.fitnessIndexId])

  return (
    <>
    { (fitnessIndex || noIndex) && 
      <Card sx={{marginTop: '5px'}} variant="outlined" style={{textAlign: 'center'}}>
        <CardHeader title="Fitness Index Score" titleTypographyProps={{ fontSize: '1em !important', fontWeight: 'bold', color: 'text.secondary', variant: 'body2'}}></CardHeader>
        <CardContent >
        { noIndex && props.manufacturer && 
          <Stack spacing={1}>
            <Typography variant="body2">No index found for this facility</Typography>
            <LoadingButton variant="outlined" loading={creatingIndex} onClick={createFitnessIndex}>Create Index</LoadingButton>
          </Stack>
        }
        { fitnessIndex &&
          <Link to={`/fitness-indexes/${fitnessIndex.id}`}>
            <Typography variant="body2">Last Updated {new Date(fitnessIndex.modifiedOn).toLocaleDateString()}</Typography>
            <MiniStatBox value={`${Number(fitnessIndex.totalScore).toFixed(2)}%`} />
          </Link>
        }
        </CardContent>
      </Card>
    }
    </>
  )

}