import FormElement from './FormElement'
import FormCard from './FormCard'
import CustomAutoComplete from '../form/CustomAutoComplete'
import { TextField } from '@mui/material'
import { useEffect, useState, useContext, useMemo } from 'react'
import { ApiGateway } from '../config/config'
import { getRequestConfig, getToken } from '../auth/auth'
import axios from 'axios'
import { Divider as MuiDivider, Grid, Button } from '@mui/material';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material'
import { Delete } from '@mui/icons-material'
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

const getFirstItem = (str = '', delimiter) => {
  if (str === null) return ''
  if (str.indexOf(delimiter) === -1) return str
  return str.split(delimiter)[0]
}

export default function ProductSelection(props = {}) {

  const {
    setDirty,
    isAnyone,
    gridItemProps,
    loadingExistingImLog,
    fwGridItemProps,
    startingValues = {},
    setValues,
    disabled,
  } = props

  const [selectionType, setSelectionType] = useState('product')

  const [brandList, setBrandList] = useState([])
  const [packageList, setPackageList] = useState([])
  const [packageMaterialList, setPackageMaterialList] = useState([])
  const [packageUnitTypeList, setPackageUnitTypeList] = useState([])
  const [packageTypeList, setPackageTypeList] = useState([])
  const [productList, setProductList] = useState([])
  const [packagePackMaterialList, setPackagePackMaterialList] = useState([])
  const [servingSizeList, setServingSizeList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const defaultState = ''
  const [brand, setBrand] = useState(null)
  const [packageIds, setPackageIds] = useState([])
  const [packageMaterial, setPackageMaterial] = useState(null)
  const [packageUnitType, setPackageUnitType] = useState(null)
  const [packageType, setPackageType] = useState(null)
  const [packagePackMaterial, setPackagePackMaterial] = useState(null)
  const [servingSize, setServingSize] = useState(null)
  const [product, setProduct] = useState(null)
  const [productDateCode, setProductDateCode] = useState(null)
  const [otherProduct, setOtherProduct] = useState(null)

  const notNullOrUndefined = (v) => v !== null && v !== undefined
  const notNullOrUndefinedOrEmpty = (v) => notNullOrUndefined(v) && v !== '' && Object.keys(v).length > 0

  useEffect(() => {
    if (notNullOrUndefinedOrEmpty(startingValues?.otherProduct) && otherProduct === null) {
      setSelectionType('other')
      setOtherProduct(startingValues?.otherProduct)
    }
    if (notNullOrUndefined(startingValues?.brand) && brand === null) setBrand({ name: startingValues?.brand, shortName: getFirstItem(startingValues?.brand, '|') })
    if (notNullOrUndefined(startingValues?.packageMaterial) && packageMaterial === null) setPackageMaterial(startingValues?.packageMaterial)
    if (notNullOrUndefined(startingValues?.packageUnitType) && packageUnitType === null) setPackageUnitType(startingValues?.packageUnitType)
    if (notNullOrUndefined(startingValues?.packageType) && packageType === null) setPackageType(startingValues?.packageType)
    if (notNullOrUndefined(startingValues?.packagePackMaterial) && packagePackMaterial === null) setPackagePackMaterial(startingValues?.packagePackMaterial)
    if (notNullOrUndefined(startingValues?.servingSizeUom) && servingSize === null) setServingSize({ uom: startingValues?.servingSizeUom, value: startingValues?.servingSizeValue, label: `${startingValues.servingSizeValue} ${startingValues.servingSizeUom}` })
    if (notNullOrUndefined(startingValues?.product) && product === null) setProduct({ name: startingValues?.product, shortName: getFirstItem(startingValues?.product, '|') })
    if (notNullOrUndefined(startingValues?.productDateCode) && productDateCode === null) setProductDateCode(startingValues?.productDateCode)
  }, [startingValues])

  const resetSelections = () => {
    setBrand(defaultState)
    setPackageIds([])
    setPackageMaterial(defaultState)
    setPackageUnitType(defaultState)
    setPackageType(defaultState)
    setPackagePackMaterial(defaultState)
    setServingSize(defaultState)
    setProduct(defaultState)
    setOtherProduct(defaultState)

    setPackageList([])
    setPackageMaterialList([])
    setPackageUnitTypeList([])
    setPackageTypeList([])
    setPackagePackMaterialList([])
    setServingSizeList([])
    setProductList([])
  }

  useEffect(() => {
    const values = {
      brand: brand?.name,
      packageMaterial,
      packageUnitType,
      packageType,
      packagePackMaterial,
      servingSizeUom: servingSize?.uom,
      servingSizeValue: servingSize?.value,
      product: product?.name,
      productDateCode,
      otherProduct
    }
    setValues(values)
  }, [
    // setValues,
    brand,
    packageMaterial,
    packageUnitType,
    packageType,
    packagePackMaterial,
    servingSize,
    product,
    productDateCode,
    otherProduct
  ])

  useEffect(() => {
    setPackageIds(packageList.filter(f => {
      return f.materialCode === packageMaterial &&
        f.packageUnitType === packageUnitType &&
        f.packageType === packageType &&
        f.packagePackMaterial === packagePackMaterial
    }))
  }, [
    packageList,
    packageMaterial,
    packageUnitType,
    packageType,
    packagePackMaterial,
  ])

  useEffect(() => {
    (async () => {
      const loadUpcOptions = async () => {
        const token = await getToken()

        const basePath = `${ApiGateway.upc}/search`
        const options = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token.idToken}`
          }
        }

        if (!brand?.name) return

        const search = {
          brandName: brand?.name,
          package: {
            materialCode: packageMaterial || undefined,
            customType: packageUnitType || undefined,
            type: packageType || undefined,
            packPackagingMaterial: packagePackMaterial || undefined
          },
          productName: product?.name,
          servingSizeUom: servingSize?.uom,
          servingSizeValue: servingSize?.value
        }

        const response = await axios.post(basePath, search, options)
        if (response.data) {
          const { results } = response.data
          const { upcs, packages, servingSizes, products } = results

          setPackageList(packages)
          setServingSizeList(
            servingSizes.map(
              ss => ({ ...ss, label: `${ss.value} ${ss.uom}` })
            )
          )
          setProductList(products)
        }
      }

      setIsLoading(true)
      await Promise.all([
        loadUpcOptions()
      ])
      setIsLoading(false)
    })()
  }, [
    brand,
    servingSize,
    product,
    packageMaterial,
    packageUnitType,
    packageType,
    packagePackMaterial,
    // startLoading,
    // stopLoading
  ])


  useEffect(() => {
    const packageMaterials = packageList.reduce((s, p) => {
      if (p.materialCode) s.push(p.materialCode)
      return s
    }, [])

    setPackageMaterialList([...new Set(packageMaterials)])
  }, [packageList])


  useEffect(() => {
    const packageUnitTypes = packageList.reduce((s, p) => {
      if (p.customType) s.push(p.customType)
      return s
    }, [])

    setPackageUnitTypeList([...new Set(packageUnitTypes)])
  }, [packageList])

  useEffect(() => {
    const packageTypes = packageList.reduce((s, p) => {
      if (p.type) s.push(p.type)
      return s
    }, [])

    setPackageTypeList([...new Set(packageTypes)])
  }, [packageList])

  useEffect(() => {
    const packagePackMaterials = packageList.reduce((s, p) => {
      if (p.packPackagingMaterial) s.push(p.packPackagingMaterial)
      return s
    }, [])

    setPackagePackMaterialList([...new Set(packagePackMaterials)])
  }, [packageList])

  useEffect(() => {
    const loadBrandOptions = async () => {
      const token = await getToken()

      const basePath = `${ApiGateway.brand}/search`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.idToken}`
        }
      }

      const search = {}

      const response = await axios.post(basePath, search, options)
      if (response.data) {
        const { results } = response.data
        setBrandList(results.map((b) => {
          return { name: b.name, shortName: b.shortName }
        }))
      }
    }

    (async () => {
      await loadBrandOptions()
    })();
  }, [brand])

  useEffect(() => {
    if (packageMaterialList.length === 1) {
      if (packageMaterialList[0] === packageMaterial) return
      setPackageMaterial(packageMaterialList[0])
    }
  }, [packageMaterialList])

  useEffect(() => {
    if (packageUnitTypeList.length === 1) {
      if (packageUnitTypeList[0] === packageUnitType) return
      setPackageUnitType(packageUnitTypeList[0])
    }
  }, [packageUnitTypeList])

  useEffect(() => {
    if (packageTypeList.length === 1) {
      if (packageTypeList[0] === packageType) return
      setPackageType(packageTypeList[0])
    }
  }, [packageTypeList])

  useEffect(() => {
    if (packagePackMaterialList.length === 1) {
      if (packagePackMaterialList[0] === packagePackMaterial) return
      setPackagePackMaterial(packagePackMaterialList[0])
    }
  }, [packagePackMaterialList])

  useEffect(() => {
    if (productList.length === 1) {
      if (productList[0].name === product?.name) return
      setProduct(productList[0])
    }
  }, [productList])

  useEffect(() => {
    if (servingSizeList.length === 1) {
      if (servingSizeList[0].id === servingSize?.id) return
      setServingSize(servingSizeList[0])
    }
  }, [servingSizeList])

  const Divider = function () {
    return < Grid item {...fwGridItemProps}>
      <MuiDivider style={{
        py: 0,
        width: '95%',
        borderRadius: 2,
        border: '.05em solid',
        borderColor: '#bfbfbf',
        backgroundColor: '#bfbfbf',
        marginLeft: "2.5%"
      }} orientation="vertical" flexItem />
    </Grid >
  }

  return (
    <Grid {...fwGridItemProps} item>
      <FormCard
        title={`${props.title}`}
        defaultExpanded={props.defaultExpanded}
        showProgress={false}
        summaryStyle={{ border: '.5px solid gray', borderBottomWidth: '0' }}
        detailsStyle={{ border: '.5px solid gray', borderTopWidth: '0' }}
      >
        <FormElement size='small' show={isAnyone} gridItemProps={fwGridItemProps} loading={false}>
          <ToggleButtonGroup
            disabled={loadingExistingImLog || isLoading || disabled}
            color="primary"
            size='small'
            value={selectionType}
            exclusive
            onChange={(e) => {
              resetSelections()
              setSelectionType(e.target.value)
            }}
            aria-label="Product Selection"
          >
            <ToggleButton value="product">Select Product</ToggleButton>
            <ToggleButton value="other">Other</ToggleButton>
          </ToggleButtonGroup>
        </FormElement>

        {selectionType === 'product' && <>
          <FormElement size='small' show={isAnyone} gridItemProps={fwGridItemProps} loading={loadingExistingImLog || isLoading}>
            <CustomAutoComplete
              disabled={disabled}
              sort="asc"
              optionsList={brandList}
              onChange={(event, newValue) => {
                resetSelections()
                setBrand(newValue)
              }}
              value={brand || ''}
              controlLabel="Brand"
              optionLabelProp="shortName"
              optionIdProp="name"
            />
          </FormElement>

          <Divider />

          <FormElement size='small' show={isAnyone} gridItemProps={fwGridItemProps} loading={loadingExistingImLog || isLoading}>
            <CustomAutoComplete
              sort="asc"
              disabled={!brand || disabled}
              optionsList={productList}
              onChange={(event, newValue) => {
                setProduct(newValue)

              }}
              value={product || ''}
              controlLabel="Product"
              optionLabelProp="shortName"
              optionIdProp="name"
            />
          </FormElement>

          <Divider />
          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog || isLoading}>
            <CustomAutoComplete
              sort="asc"
              disabled={!brand || disabled}
              optionsList={packageMaterialList}
              onChange={(event, newValue) => {
                setPackageMaterial(newValue)
              }}
              value={packageMaterial || ''}
              controlLabel="Package Material"
            />
          </FormElement>

          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog || isLoading}>
            <CustomAutoComplete
              sort="asc"
              disabled={!brand || disabled}
              optionsList={packageUnitTypeList}
              onChange={(event, newValue) => {
                setPackageUnitType(newValue)

              }}
              value={packageUnitType || ''}
              controlLabel="Package Type (Unit)"
            />
          </FormElement>

          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog || isLoading}>
            <CustomAutoComplete
              sort="asc"
              disabled={!brand || disabled}
              optionsList={packageTypeList}
              onChange={(event, newValue) => {
                setPackageType(newValue)

              }}
              value={packageType || ''}
              controlLabel="Package Type"
            />
          </FormElement>

          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog || isLoading}>
            <CustomAutoComplete
              sort="asc"
              disabled={!brand || disabled}
              optionsList={packagePackMaterialList}
              onChange={(event, newValue) => {
                setPackagePackMaterial(newValue)

              }}
              value={packagePackMaterial || ''}
              controlLabel="Packaging Pack Material"
            />
          </FormElement>

          <Divider />

          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog || isLoading}>
            <CustomAutoComplete
              sort="asc"
              disabled={!brand || disabled}
              optionsList={servingSizeList}
              onChange={(event, newValue) => {
                setServingSize(newValue)

              }}
              value={servingSize || ''}
              controlLabel="Serving Size"
              optionLabelGetter={(opt) => opt.value ? `${opt.value} ${opt.uom}` : ''}
            />
          </FormElement>

          <FormElement size='small' show={isAnyone} gridItemProps={gridItemProps} loading={loadingExistingImLog || isLoading}>
            <TextField
              id="productDateCode"
              disabled={disabled}
              size='small'
              value={productDateCode || ''}
              onChange={(event, newValue) => {
                setProductDateCode(event.target.value)
              }}
              label="Product Date Code"
              fullWidth
            />
          </FormElement>
        </>}

        <FormElement show={isAnyone && selectionType === 'other'} gridItemProps={fwGridItemProps} loading={loadingExistingImLog || isLoading}>
          <TextField
            id="otherProduct"
            disabled={disabled}
            size='small'
            value={otherProduct || ''}
            onChange={(event, newValue) => {
              setOtherProduct(event.target.value)
            }}
            label="Describe Affected Product"
            fullWidth
          />
        </FormElement>

        {!disabled && <Grid item xs={12} style={{ marginTop: '4px' }}>
          <Button
            style={{
              position: 'absolute',
              right: '14px',
              bottom: '4px'
            }}
            onClick={props.remove}
            size="small">
            Remove Product
            <Delete size="small" />
          </Button>
        </Grid>}
      </FormCard>
    </Grid>
  )
}