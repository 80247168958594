import { useContext, useEffect, useMemo, useState } from 'react'
import { calculateProgress, getSummary } from '../context/AssessmentContext'
import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import ChartJSBarChart from '../../charts/ChartJsBarChart'
import { RATINGS, RATING_LABELS } from '../../form/formUtils'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ChartJsPieChart from '../../charts/ChartJsPieChart'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import RoleContext from '../../auth/RoleContext'
import { ROLES } from '../../auth/role'
import { ASMT_STATUS } from '../../utils/constants'
import { FileDownload } from '@mui/icons-material'
import { download, generateCsv, mkConfig } from 'export-to-csv'

export default function SummaryOutcome(props={assessment: undefined, assessmentItems: [], includedModels: []}) {
  const [outcomeScores, setOutcomeScores] = useState([])
  const { assessment } = props

  const initialize = () => {

    const progress = props.includedModels.map((c) => {
      const [a,b] = calculateProgress(c, props.assessmentItems)
      return a // (a) is Category only, (a, ...b) is Category, Section
    })
    const _graphScores = progress.map((i) => {
      // return ({id: i.id, title: i.title, value: (i.earnedPoints / i.maxPoints) * 100 }) 
      const summary = getSummary(undefined, i.id, i)
      return ({id: i.id, title: i.title, value: summary.findingRating })
    })
    setOutcomeScores(_graphScores)
  }

  useEffect(() => {
    initialize()
  }, [props.includedModels])

  const getCalculatedScore = () => {
    const tots = outcomeScores.reduce((p, c) => { return p + c.value}, 0)
    return Math.round((tots / outcomeScores.length) * 100)/100
  }

  const getFilteredTotal = (categoryRegex) => {
    const filteredScores = outcomeScores.filter((s) => s.id.match(categoryRegex))
    const tots = filteredScores.reduce((p, c) => {  return p + c.value}, 0)
    return Math.round((tots / filteredScores.length) * 100)/100
  }


return (<>{ assessment &&
  <Grid container>
    <Grid item xs={12} sm={8} md={5}>
      <Typography variant='body1'>
        <Grid container columnSpacing={1} rowSpacing={.5}>
          <Grid item xs={10} textAlign={'right'} fontWeight={'bold'}>Overall Calculated Score:</Grid>
          <Grid item xs={2} textAlign={'center'}>{`${getCalculatedScore()}%`}</Grid>
          { (assessment.assessmentType === 'bu' || assessment.filter.join('').match(/[A-M]/)) &&
            <>
            <Grid item xs={10} textAlign={'right'} fontWeight={'bold'}>Quality & Food Safety Assessment Score:</Grid>
            <Grid item xs={2} textAlign={'center'}>{`${getFilteredTotal(/[A-M]/)}%`}</Grid>
            </>
          }
          { (assessment.assessmentType === 'bu' || assessment.filter.join('').match(/[N-Z]/)) &&
            <>
            <Grid item xs={10} textAlign={'right'} fontWeight={'bold'}>Environmental & Safety Assessment Score:</Grid>
            <Grid item xs={2} textAlign={'center'}>{`${getFilteredTotal(/[N-Z]/)}%`}</Grid>
            </>
          }
        </Grid>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <ChartJSBarChart
      title={'Outcome by Program'}
      // barColor={'#FFCC00'}
      barColor={outcomeScores.map(s => {
        let color = '#FF0000'
        const v = s.value
        if (v > 10) color = '#FF4500'
        if (v > 20) color = '#FFA500'
        if (v > 30) color = '#FF8C00'
        if (v > 40) color = '#FFFF00'
        if (v > 50) color = '#ADFF2F'
        if (v > 60) color = '#90EE90'
        if (v > 70) color = '#00FF00'
        if (v > 80) color = '#50C878'
        if (v > 90) color = '#006400'
        return color
      })}
      labelsArray={ outcomeScores.map(s => {
        return `${s.title}, ${Math.round(s.value * 100)/100}%`
      })}
      dataArray={ outcomeScores.map(s => s.value)}
      xScaleSuggestedMax={100}
    />
    </Grid>
  </Grid>
}</>)}