import { useContext, useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import ChartJSBarChart from '../../charts/ChartJsBarChart'
import { RATINGS, RATING_LABELS } from '../../form/formUtils'
import ChartJsPieChart from '../../charts/ChartJsPieChart'


const collectRatings = (_itemsList) => {
  const _ratingCounts = {}
  const _categoryTotals = {}

  _itemsList.forEach((item) => {
    if (item.findingRating && !['na','not_assessed', 'no_finding'].includes(item.findingRating)) {
      const groupRating = _ratingCounts[item.findingRating] || 0
      _ratingCounts[item.findingRating] = groupRating + 1

      const category = item.itemId.split('')[0]
      const categoryRating = _categoryTotals[category] || 0
      _categoryTotals[category] = categoryRating + 1
    }
  })

  return [_ratingCounts, _categoryTotals]
}

export default function TotalFindings(props={assessmentItems: [], assessment: undefined}) {
  const [ratingTotals, setRatingTotals] = useState([])
  const [areaTotals, setAreaTotals] = useState([0,0,0])
  const assessment = props.assessment

  const initializeSummary = async () => {

    const [_ratingTotals, _categoryTotals] = collectRatings(props.assessmentItems)
    setRatingTotals(_ratingTotals)
    setAreaTotals(Object.keys(_categoryTotals).reduce((p, c) => {
      if (c.match(/[A-M]/)) {
        p[0] += _categoryTotals[c]
      } else if (c.match(/[N-P]/)) {
        p[1] += _categoryTotals[c]
      } else if (c.match(/[Q-T]/)) {
        p[2] += _categoryTotals[c]
      }
      return p
    },[0, 0, 0]))
  }


  useEffect(() => {
    initializeSummary()
  }, [props.assessment])


  return ( <>{ assessment && <>
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={12} md={8}>
              <ChartJSBarChart
              title={'Total Findings'} 
              barColor={'#FF9900'}
              labelsArray={[
                RATING_LABELS[RATINGS.high_risk_non_conformance],
                RATING_LABELS[RATINGS.non_conformance],
                RATING_LABELS[RATINGS.conditional_non_conformance],
                RATING_LABELS[RATINGS.improvement_opportunity]
              ]}
              dataArray={[
                ratingTotals[RATINGS.high_risk_non_conformance] || 0,
                ratingTotals[RATINGS.non_conformance] || 0, 
                ratingTotals[RATINGS.conditional_non_conformance] || 0, 
                ratingTotals[RATINGS.improvement_opportunity] || 0
              ]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TableContainer>
                <Table>
                  <TableBody>
                    { Object.keys(ratingTotals).map((r, ridx) => (
                      <TableRow key={ridx}>
                        <TableCell sx={{border: 1, textAlign:'center'}}>{RATING_LABELS[r]}<br/>{ratingTotals[r]}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow key="total">
                      <TableCell  sx={{border: 1, textAlign:'center', backgroundColor: '#FF9900', fontWeight: 'bold'}}>Total&nbsp;Findings<br/>{Object.values(ratingTotals).reduce((p,c) => p+c, 0)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems='center' marginTop={2}>          
            <Grid item xs={12} md={8} alignSelf='right' maxHeight={375}>
            <Typography variant='h5'>Area Totals</Typography>
              <ChartJsPieChart 
                title='' 
                dataArray={areaTotals} 
                labelsArray={['Quality & Food Safety', 'Environmental','Safety' ]}
                backgroundColorsArray={['#003366','#99CC33','#CC3333']}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{border: 1, textAlign:'center'}}>Quality&nbsp;&amp;&nbsp;Food&nbsp;Safety<br/>{areaTotals[0]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{border: 1, textAlign:'center'}}>Environmental<br/>{areaTotals[1]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{border: 1, textAlign:'center'}}>Safety<br/>{areaTotals[2]}</TableCell>
                    </TableRow>
                    <TableRow key="total">
                      <TableCell  sx={{border: 1, textAlign:'center', backgroundColor: '#FF9900', fontWeight: 'bold'}}>Total&nbsp;Findings<br/>{Object.values(areaTotals).reduce((p,c) => p+c, 0)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          </>
  }</>)
}