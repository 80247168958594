import { getRequestConfig } from '../auth/auth';
import { ApiGateway } from '../config/config';
import axios from 'axios';
import bus from '../utils/EventBus'
import Event from '../utils/EventNames'
import { ASMT_STATUS } from '../utils/constants';

export const createManufacturerProfile = async (manufacturer = undefined) => {
  const basePath = `${ApiGateway.manufacturerProfile}`
  const requestConfig = await getRequestConfig()
  try {
    if ( !manufacturer ) throw new Error('No Manufacturer provided')
    const response = await axios.post(basePath, manufacturer, requestConfig)
    
    return response.data
  } catch (error) {
    return {
      status: 'error',
      error
    }
  }
}
export const loadManufacturerProfile = async (search = {id: undefined, manufacturerId: undefined}, requestConfig) => {
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }
    
  const basePath = `${ApiGateway.manufacturerProfile}/search`
  if ( !search.id && !search.manufacturerId ) return undefined
  
  const response = await axios.post(basePath, search, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results[0]
  }
  return undefined
}

export const saveManufacturerProfile = async (_profile) => {
  const requestConfig = await getRequestConfig()
  
  const basePath = `${ApiGateway.manufacturerProfile}/${_profile.id}`

  const payload = _profile

  try {
    const result = await axios.post(basePath, payload, requestConfig)
    return result
  } catch (error) {
    return error
  }
}

export const getOpenAssessment = async (_mfrProfileId, requestConfig) => {
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }

  const basePath = `${ApiGateway.assessments}/search`
  const search = { 
      mfrProfileId: _mfrProfileId,
      status: [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE].join(',')
    }
  const response = await axios.post(basePath, search, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results[0]
  }
  return undefined
}

export const loadOpenAssessment = async (search = {
  manufacturerId: undefined,
  mfrProfileId: undefined
}, requestConfig) => {
  if ( (search.manufacturerId ?? '') === '' && (search.mfrProfileId ?? '') === '') {
    throw new Error('One of manufacturerId, mfrProfileId required to loadOpenAssessment')
  }
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }

  const basePath = `${ApiGateway.assessments}/search`
  const response = await axios.post(basePath, {...search, status: [ASMT_STATUS.NEW, ASMT_STATUS.ACTIVE].join(',') }, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results[0]
  }
  return undefined
}

export const loadAllProductionCodes = async (requestConfig) => {
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }

  const basePath = `${ApiGateway.facilityManagement}/manufacturer/all-production-codes`
  const response = await axios.get(basePath, requestConfig)
  if (response.data) {
    return response.data
  }
  return undefined
}

export const saveManufacturer = async (manufacturerUuid, manufacturerDetail, requestConfig) => {
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }

  const basePath = `${ApiGateway.facilityManagement}/manufacturer${manufacturerUuid ? '/' + manufacturerUuid : ''}`
  const payload = { ...manufacturerDetail }
  const response = await axios.put(basePath, payload, requestConfig)
  if (response.data) {
    const { status, instance, created } = response.data
    console.log(`Save: ${status}`,`Instance: ${JSON.stringify(instance,null,2)}`, `Created: ${created}`)
    return response.data
  }
}

export const searchManufacturers = async (search, requestConfig) => {
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }
    
  const basePath = `${ApiGateway.facilityManagement}/manufacturer/search`
  const response = await axios.post(basePath, search, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results || []
  }
}

export const loadManufacturer = async (_mfrId, requestConfig) => {
  if ( !requestConfig ) {
    requestConfig = await getRequestConfig()
  }
    
  const basePath = `${ApiGateway.facilityManagement}/manufacturer/search`
  const search = {
    searchIncludes: 'ownership'
  }
  if (_mfrId) {
    search.uuid = _mfrId
  }
  const response = await axios.post(basePath, search, requestConfig)
  if (response.data) {
    const { results } = response.data
    return results?.length ? results[0] : {}
  }
}

export const saveMfrProfileChildItem = async (itemType, item, manufacturerProfileId) => {
  const requestConfig = await getRequestConfig()

  const itemTypePath = [itemType, item.id ?? ''].join('/')
  const path = `${ApiGateway.manufacturerProfile}/${manufacturerProfileId}/${itemTypePath}`

  const result = await axios.post(path, item, requestConfig)
  return result.data
}

export const createOrUpdateMfrProfileChildItem = async (productItemType, item, itemListSetter, manufacturerProfileId) => {
  try {
    const result = await saveMfrProfileChildItem(productItemType, item, manufacturerProfileId)
    bus.emit(Event.ALERT, { "text": 'Saved', "style": "success", "duration": 3000 })
    itemListSetter(prev => {
      prev = prev && Array.isArray(prev) ? prev : []
      let isNew = true
      const res = prev.reduce((p, c) => {
        if (c.id === result.childItem.id) {
          isNew = false
          p.push(result.childItem)
        } else {
          p.push(c)
        }
        return p
      }, [])
      if (isNew) res.push(result.childItem)

      return res
    })
  } catch (error) {
    console.error(error)
    bus.emit(Event.ALERT, { "text": 'Saving item failed', "style": "error", "duration": 5000 })
    return error
  }
}

export const deleteChildItem = async (childItemType, item, itemListSetter, manufacturerProfileId) => {
  try {
    const itemTypePath = [childItemType, item.id ?? ''].join('/')
    const path = `${ApiGateway.manufacturerProfile}/${manufacturerProfileId}/${itemTypePath}`
    const requestConfig = await getRequestConfig()
    await axios.delete(path, requestConfig)
    bus.emit(Event.ALERT, { "text": 'Deleted', "style": "success", "duration": 3000 })
    itemListSetter(prev => {
      prev = prev && Array.isArray(prev) ? prev : []
      const res = prev.reduce((p, c) => {
        if (c.id !== item.id) {
          p.push(c)
        }
        return p
      }, [])

      return res
    })
  } catch (error) {
    console.error(error)
    bus.emit(Event.ALERT, { "text": 'Failed to delete', "style": "error", "duration": 5000 })
    return error
  }
}
