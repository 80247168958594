import { default as React, useContext, useEffect, useState, useCallback } from 'react'
import { Card, Box } from '@mui/material'
import ContactTable from '../../facilityManagement/ContactTable';
import TabPanel from '../../form/TabPanel'
import { ROLES } from '../../auth/role.js'
import RoleContext from '../../auth/RoleContext.js'
import { ParentType, getEntityContacts, getContactTypes } from '../../facilityManagement/data'


export default function ManufacturerContactsSection(props = {}) {

  const roleDetails = useContext(RoleContext)

  const { manufacturer = {} } = props
  const [loading, setLoading] = useState(true);

  const [internalContacts, setInternalContacts] = React.useState([]);
  const [externalContacts, setExternalContacts] = React.useState([]);
  const [contactTypes, setContactTypes] = React.useState([]);

  const loadSection = async (mfrUuid) => {
    setLoading(true)
    const [_internalContacts, _externalContacts, _contactTypes] = await Promise.all([
      getEntityContacts({
        facilityOrOwnershipUuid: mfrUuid,
        parentType: ParentType.manufacturer,
        isInternal: true,
        category: 'manufacturer'
      }),
      getEntityContacts({
        facilityOrOwnershipUuid: mfrUuid,
        parentType: ParentType.manufacturer,
        isInternal: false,
        category: 'manufacturer'
      }),
      getContactTypes('manufacturer')
    ])
    setInternalContacts(_internalContacts)
    setExternalContacts(_externalContacts)
    setContactTypes(_contactTypes)
    setLoading(false)
  }

  useEffect(() => {
    (async () => {
      if (manufacturer?.uuid) loadSection(manufacturer?.uuid)
    })()
  }, [manufacturer])

  const editingEnabled = roleDetails.hasRole([
    ROLES.BOTTLER_CORP,
    ROLES.BOTTLER,
    ROLES.QSE_MGR,
    ROLES.MFI_ADMIN,
    ROLES.ADMIN
  ])

  const editCCContactsEnabled = roleDetails.hasRole([
    ROLES.QSE_MGR,
    ROLES.MFI_ADMIN,
    ROLES.ADMIN
  ])

  const afterSave = useCallback(() => {
    (async () => {
      if (manufacturer?.uuid) loadSection(manufacturer?.uuid)
    })()
  }, [manufacturer])

  return (

    <Card sx={{ padding: '12px' }}>

      <TabPanel
        tabs={[
          {
            label: "Facility Contacts",
            el: <ContactTable
              loading={loading}
              parentType={ParentType.manufacturer}
              isInternal={false}
              facilityOrOwnership={manufacturer}
              editMode={editingEnabled}
              contacts={externalContacts}
              setContacts={setExternalContacts}
              contactTypes={contactTypes.filter(ct => !ct.isInternal)}
              afterSave={afterSave}
              key={'external'}
            />
          },
          {
            label: "Coca-Cola Contacts",
            el: <ContactTable
              loading={loading}
              parentType={ParentType.manufacturer}
              isInternal={true}
              facilityOrOwnership={manufacturer}
              editMode={editCCContactsEnabled}
              contacts={internalContacts}
              setContacts={setInternalContacts}
              contactTypes={contactTypes.filter(ct => ct.isInternal)}
              afterSave={afterSave}
              key={'internal'}
            />
          },
        ]}
      />

    </Card>
  )
}