import { useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Grid, Skeleton } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';
import RoleContext from '../auth/RoleContext.js'
import { searchManufacturers } from './data.js'

export default function ListManufacturers(props = { minimal: false }) {
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(true)

  const [dataRows, setDataRows] = useState([])
  const [typeFilterOptions, setTypeFilterOptions] = useState([])
  const roleDetails = useContext(RoleContext)

  const listColumns = useMemo(() => [
    {
      accessorKey: 'manufacturerId',
      header: 'Facility ID',
      size: 115,
      grow: false,
      editable: false,
      filterVariant: 'text',
      muiFilterTextFieldProps: { placeholder: 'Filter by ID' },
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({row}) => {
        const id = row.original.manufacturerId
        return [undefined, null, ''].includes(id) ? 'No Global ID' : id
      }
    },
    {
      accessorKey: 'manufacturerName',
      header: 'Name',
      size: 300,
      grow: true,
      editable: false,
      filterVariant: 'text',
      Cell: ({ row }) => (
        <Link to={"/manufacturer/edit/" + row.original.uuid + '/details'}>
          {row.original.manufacturerName}
        </Link>
      )
    },{
      accessorKey: 'type',
      header: 'Type',
      size: 125,
      grow: false,
      editable: false,
      filterVariant: 'multi-select',
      filterSelectOptions: typeFilterOptions,
      muiFilterTextFieldProps: { placeholder: 'Select...' },
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },{
      accessorKey: 'productionCode',
      header: 'Code(s)',
      size: 125,
      grow: false,
      editable: false,
      filterVariant: 'text',
      muiFilterTextFieldProps: { placeholder: 'Filter Code' },
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: 'Ownership.ownershipName',
      header: 'Ownership',
      size: 200,
      grow: true,
      editable: false,
      filterVariant: 'text',
      Cell: ({ row }) => {
        const ownership = row.original.Ownership
        return (
        <Link to={"/ownership/edit/" + ownership.uuid + '/details'}>
          {`${ownership.ownershipName} (${[undefined, null, ''].includes(ownership.ownershipId) ? 'No Global ID' : ownership.ownershipId})`}
        </Link>
      )}
    }
  ])

  const getManufacturerList = async () => {
    setLoading(true)

    const search = props.searchFilter ?? {
      isActive: true
    }
    search.searchIncludes = ['ownership']

    const rows = await searchManufacturers(search)
    setDataRows(rows || [])
    const types = rows.reduce((p, v) => {      
      const t = v.type
      if (t && !p.includes(t)) {
        p.push(t)
      }
      return p
    }, [])
    setTypeFilterOptions(types)
    props.stopLoading()
    setLoading(false)
  }



  useEffect(() => {
    if (isMounted()) {
      getManufacturerList()
    }
  }, [])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters && !props.minimal,
    initialState: {
      showColumnFilters: !props.minimal,
      sorting: [{ id: 'manufacturerName', desc: false }],
      pagination: (props.minimal === true) ? undefined : {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.rowsPerPage ?? 15
      }
    },
    enablePagination: (props.minimal !== true),
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: (props.minimal === true) ? undefined : {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: false,
  });

  //  <AddRecordDialog title={"Add Employee User"} content={addRecordForm} open={props.showAddRecord} hideDialog={hideAddRecord} createRecord={addAssessment} buttonLoading={buttonLoading}/>
  return (
    <Grid container rowSpacing={2} >
      <Grid item xs={12}>
        { loading && <Skeleton variant='rounded' animation='wave' height={100} sx={{margin: '5px'}}/>}
        { !loading && <MaterialReactTable table={table} />}
      </Grid>
    </Grid>
  )

}