import { useState, useEffect } from 'react'
import { ApiGateway } from '../config/config'
import axios from 'axios'
import { getToken } from './auth'


class RoleService {
  static #instanceRequest = false
  static #roleServiceInstance = undefined
  #queued = false

  constructor() {
    if (!RoleService.#instanceRequest) {
      throw new TypeError('Cannot be constructed. Please use RoleService.getInstance()')
    }
    RoleService.#instanceRequest = false
  }

  static getInstance() {
    if (! RoleService.#roleServiceInstance) {
      RoleService.#instanceRequest = true
      RoleService.#roleServiceInstance = new RoleService()
    }
    return RoleService.#roleServiceInstance
  }

  #getMaxPrivelege(roles) {
    const maxPrivelege = roles.reduce((p,c) => {
      if (!p || p.id > c.id) {
        return c
      }
      return p
    })
    return maxPrivelege
  }

  async getUserRole(msalInstance) {
    if (this.#queued) {
      return
    }
    this.#queued = true

    const token = await getToken()
    const koId = token?.idTokenClaims?.koId
    if (! koId) return undefined

    const basePath = `${ApiGateway.profile}/${koId}`
    const options = {
      headers: {        
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.idToken}`
      }
    }
    const response = await axios.get(basePath, options)
    let _role = undefined
    if (response.data) {
      _role = this.#getMaxPrivelege(response.data.roles)
    }
    this.#queued = false
    return _role
  }
}

export const ROLES = {
  ADMIN: {id: 1, name: 'Admin'},
  MFI_ADMIN: {id: 2, name: 'MFI Admin'},
  QSE_MGR: {id: 3, name: 'QSE Governance Manager'},
  BOTTLER: {id: 20, name: 'Bottler (Facility)'},
  BOTTLER_CORP: {id: 19, name: 'Bottler (Corporate)'},
  IM_MANAGER: {id: 24, name: 'IM Manager'},
  IM_VIEWER: {id: 25, name: 'IM Viewer'},
}

export const getRoleById = (id) => {
  return Object.values(ROLES).find(r => r.id === id)
}

function getStorageKeyName() {
  const ds = new Date().toLocaleDateString()
  return btoa(ds)
}
function getRoleEncoded(rob) {
  const enc = btoa(JSON.stringify(rob))
  return enc
}

function getStoredRole() {
  const encodedRole = window.sessionStorage.getItem(getStorageKeyName())
  if (encodedRole == null) {
    return null
  }
  const dec = atob(encodedRole)
  const rob = JSON.parse(dec)
  return rob
}
function serializeRole(role_ob) {
  window.sessionStorage.setItem(getStorageKeyName(),getRoleEncoded(role_ob))
}

export function cleanup() {
  window.sessionStorage.removeItem(getStorageKeyName())
}

export function useRole() {
  // const { instance } = useMsal()
  const [role, setRole] = useState()
  const instance = undefined

  async function getAccountRole() {
    if (! role) {
      const storedRole = getStoredRole()
      if (storedRole != null) {
        setRole(storedRole)
        return storedRole
      } else {              
        const _role = await RoleService.getInstance().getUserRole(instance)        
        if (_role) {
          setRole(_role)
          serializeRole(_role)
          return _role
        } else {
          setRole(undefined)
          return undefined
        }
      }
    }
  }

  const hasRole = (roles) => {
    let _roles
    if (Array.isArray(roles)) {
      _roles = roles
    } else if (typeof roles === 'object') {
      _roles = [roles]
    }
    return role && _roles.some((r) => r.id === role.id)
  }

  const hasAnyRole = () => {
    return role && Object.values(ROLES).some((r) => r.id === role.id)
  }

  const cleanup = () => {
    cleanup()
  }

  // getAccountRole()

  useEffect(() => {
    if (!role) {
      (async () => {
        await getAccountRole()
      })()
    }
  },[])

  return {
    role,
    hasRole,
    hasAnyRole,
    cleanup
  }
}