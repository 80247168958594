import React from 'react';

const Chart = {
  animationDuration: 750,
  interpolation: 'linear',
  groupStrokes: ['#00a65a', '#00c0ef', '#f39c12' ,'#dd4b39'],
  groupFills: ['#00a65a', '#00c0ef', '#f39c12' ,'#dd4b39'],
  stroke: '#999999',
  strokes: ['#00a65a', '#003947', '#00c0ef', '#f39c12', '#dd4b39', '#663399', '#333333' ],
  fill: 'transparent',
  fills: ['#00a65a', '#003947', '#00c0ef', '#f39c12', '#dd4b39', '#663399', '#333333' ]
};

export default class ScoreDot extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
    this.outerSelection = {};
  }

  getClassName() {
    let className = 'dot-score';
    if (this.props.onClick) {
      className += ' clickable';
    }
    return className;
  }

  getFill(value) {
    let idx = 3;
    if (value >= 90) {
      idx=0;
    } else if (value >= 80) {
      idx=1;
    } else if (value >= 70) {
      idx=2;
    }

    return Chart.groupFills[idx];
  }

  handleOnClick = () => {
    const { payload, onClick } = this.props;

    if (onClick) {
      onClick(payload, this.props.index);
    }
  }

  select(callback) {
    if (!this.state.selected) {
      this.setState({selected: true}, () => {
        if (callback) {
          callback();
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  deselect(callback) {
    if (this.state.selected) {
      this.setState({selected: false}, () => {
        if (callback) {
          callback();
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  render () {
    const {cx, cy, value} = this.props;
    const fill = this.getFill(value);

    return (
      <svg className={this.getClassName()} onClick={this.handleOnClick} x={cx - 10} y={cy - 10} width={20} height={20} fill={fill} viewBox="0 0 1024 1024">
        <circle cx={510} cy={510} r={360} fill={fill} />
        {this.state.selected &&
          <svg ref={(me)=> {
            this.outerSelection = me;
          }}>
            <circle cx={510} cy={510} r={360} stroke="#000000" strokeWidth={100} fill="none">
              <animate id="one" attributeName="r" values="0; 360px"  begin="0s" dur=".15s" calcMode="linear" />
            </circle>
            <circle cx={510} cy={510} r={260} stroke="#ffffff" strokeWidth={100} fill="none">
              <animate id="two" attributeName="r" values="0; 260px"  begin="0s" dur=".15s" calcMode="linear" />
            </circle>
          </svg>}
      </svg>
    );
  }
}
