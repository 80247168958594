import { useEffect, useState } from 'react'
import Page from '../layout/Page'
import { Stack } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Healing } from '@mui/icons-material'
import ProfileSelectionControl from '../manufacturer/ProfileSelectionControl.js'
import OwnershipSelectionControl from '../manufacturer/OwnershipSelectionControl.js'
import ListViewCommandBar from '../commandBar/ListViewCommandBar.js'
import ListCAPs from './ListCAPs.js'

export default function ListCAPsPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const [showFilters, setShowFilters] = useState(false)

  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [mfrProfileId, setMfrProfileId] = useState(params.mfrProfileId || undefined)
  const [ownershipId, setOwnershipId] = useState(searchParams.get('o') ?? undefined)
  const [ownershipUuid, setOwnershipUuid] = useState(undefined)

  const performSelectManufacturer = async (_mfrProfileId) => {
    setMfrProfileId(_mfrProfileId)
    navigate(`/corrective-action-plan/list${_mfrProfileId ? `/${_mfrProfileId}` : ''}`)
  }

  const performSelectOwnership = async ([_ownershipUuid, _ownershipId]) => {
    setOwnershipUuid(_ownershipUuid)
    setOwnershipId(_ownershipId)
    navigate(`/corrective-action-plan/list${_ownershipId ? `?o=${_ownershipId}` : ''}`)
  }

  const breadcrumb = <span><Healing/>Corrective Action Plans</span>

  useEffect(() => {}, [ownershipId, mfrProfileId])

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} 
      commandBar={<ListViewCommandBar 
        showFilters={true}
        toggleFilters={toggleFilters}
        showAdd={false}
      />}
    >
    <div className='App'>      
      <Stack spacing={2} direction='row'>
        <OwnershipSelectionControl defaultOwnershipId={ownershipId} onSelectOwnership={performSelectOwnership} requireOwnershipId={true} />
        <ProfileSelectionControl defaultMfrProfileId={mfrProfileId} forOwnershipId={ownershipId} onSelectProfile={performSelectManufacturer} />
      </Stack>
      <ListCAPs search={{mfrProfileId, ownershipId}} setIsLoading={setIsLoading} showFilters={showFilters} />
    </div>
    </Page>
  )
}