import { useState, useMemo, useEffect, useCallback } from 'react'
import { Grid, FormControlLabel, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

export default function ProductionCodeListModal({data, open, onClose}) {
  const [loading, setLoading] = useState(false)
  const [dataRows, setDataRows] = useState([])

  const listColumns = useMemo(() => [
    {
      accessorKey: 'productionCode',
      header: 'Code',
      size: 75,
      grow: false
    },
    {
      accessorKey: 'usage',
      header: 'Usage',
      size: 150,
      grow: false,
      enableGlobalFilter: false
    },
    {
      accessorKey: 'facilityId',
      header: 'Facility ID',
      size: 150,
      grow: false,
    },
    {
      accessorKey: 'description',
      header: 'Description',
      size: 175,
      grow: true
    }
  ])

  useEffect(() => {
    if (data) {
      const rows = data.facilities.map(f => {
        return {
          productionCode: f.productionCode,
          usage: f.isActive === '1' ? 'Active' : 'Inactive',
          facilityId: f.manufacturerId,
          description: [f.manufacturerName, f.manufacturerAddress, f.manufacturerCity, f.manufacturerState].filter(t => t).join(', ')
        }
      })
      
      const history = data.history.map(h => { return {
        productionCode: h.productionCode,
        usage: 'History',
        facilityId: h.facilityId,
        description: h.description
      }})
      rows.push(...history)
      setDataRows(rows)
    }
  }, [data])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    positionGlobalFilter: 'left',
    enableGlobalFilter: true,
    initialState: {
      showGlobalFilter: true,
      sorting: [{ id: 'productionCode', desc: false }],
    },
    enablePagination: true,
    enableSorting: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    }
  });

  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      <DialogTitle textAlign="center">Production Code History</DialogTitle>
      <DialogContent sx={{padding: 2}}>

        <MaterialReactTable table={table} />
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={() => { onClose(); }}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};